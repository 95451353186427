import React, { useEffect, useState, useRef } from "react";
import AsideMasterAdmin from "../../SideBar/AsideMasterAdmin";
import MainMasterAdmin from "../../SideBar/MainMasterAdmin";
// import SelectDepartment from "../../Admin/Departments/SelectDepartment";
import CompanyMangement from "./Companies/CompanyMangement";
import app, { firestore, BACKEND_BASE_URL } from "../../../../base";

import axios from "axios";

function MasterAdminDashBoard({ locale, setLocale }) {
  const _MainDashBoard = "MainDashBoard",
    _Companies = "Companies";

  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [ShowMainView, setShowMainView] = useState(_MainDashBoard);
  //
  const CompaniesListener = useRef(null);
  const TotalCompaniesListener = useRef(null);
  const TotalUsersNumberListener = useRef(null);
  // const TotalUsersRemovedListener = useRef(null);

  const [AllCompaniesData, setAllCompaniesData] = useState([]);
  const [TotalCompanies, setTotalCompanies] = useState(0);
  const [TotalUsersNumber, setTotalUsersNumber] = useState(0);
  const [TotalCompaniesRemoved, setTotalCompaniesRemoved] = useState(0);
  const [TotalUsersRemoved, setTotalUsersRemoved] = useState(0);

  const _dangerDeleteCompany = "DangerDeleteCompany",
    _updateCompany = "UpdateCompany",
    _removed = "Removed",
    _removing = "Removing",
    _updating = "Updating",
    _updated = "Updated";

  const [show, setShow] = useState(false);
  const [removeOrUpdateCompany, setRemoveOrUpdateCompanyState] = useState("");
  const [removeOrUpdateCompanyMsg, setRemoveOrUpdateCompanyMsg] = useState("");
  const [updateValid, setUpdateValid] = useState(false);
  const [ShowChat, setShowChat] = useState(false);

  const removingCompanyReset = () => {
    setRemoveOrUpdateCompanyMsg("");
    setRemoveOrUpdateCompanyState("");
    setUpdateValid(false);
  };

  const handleClose = () => {
    setShow(false);
    removingCompanyReset();
  };
  const handleShow = () => setShow(true);

  async function prepareAllListeners() {
    try {
      var masterAdminInfoRef = firestore
        .collection("Info")
        .doc("Companies")
        .collection("Info");

      CompaniesListener.current = masterAdminInfoRef.onSnapshot((snapshot) => {
        const postData = [];
        snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
        setAllCompaniesData([...postData]);

        console.log(JSON.stringify(postData)); // <------remove before production.
      });

      TotalCompaniesListener.current = firestore
        .collection("Info")
        .doc("TotalCompanies")
        .onSnapshot((doc) => {
          setTotalCompanies(doc.data().TotalCompaniesCreated);
          setTotalCompaniesRemoved(doc.data().TotalCompaniesRemoved);
          //console.log("TotalCompaniesListener Current data: ", doc.data());
        });

      TotalUsersNumberListener.current = firestore
        .collection("Info")
        .doc("TotalUsers")
        .onSnapshot((doc) => {
          setTotalUsersNumber(doc.data().TotalUsersCreated);
          setTotalUsersRemoved(doc.data().TotalUsersRemoved);
          // console.log("TotalUsersListener Current data: ", doc.data());
        });
      // const rootRef = firebase.database().ref().child('react');.TotalCompaniesCreated
      // const speedRef = rootRef.child('speed');

      // speedRef.on('value', snap => {
      //   this.setState({
      //     speed: snap.val()
      //   });
      // });
    } catch (error) {
      console.log(error);

      alert("error occurred. " + error);
    }

    return;
    {
      //let token = "";
      // await app
      //   .auth()
      //   .currentUser.getIdToken()
      //   .then(function (idToken) {
      //     // <------ Check this line
      //alert(idToken); // It shows the Firebase token now
      // console.log(idToken);
      // token = idToken;const [posts, setPosts] = useState();
      // var masterAdminInfoRef = firestore
      //   .collection("Info")
      //   .doc("Companies")
      //   .collection("Info");
      // CompaniesListener.current = masterAdminInfoRef.onSnapshot(
      //   (snapshot) => {
      //     const postData = [];
      //     snapshot.forEach((doc) =>
      //       postData.push({ ...doc.data(), id: doc.id })
      //     );
      //     setAllCompaniesData([...postData]);
      //     console.log(postData); // <------remove before production.
      //   }
      // );
      // // CompaniesListener.current = firestore
      // //   .collection("Companies")
      // //   .onSnapshot((snapshot) => {
      // //     const postData = [];
      // //     snapshot.forEach((doc) =>
      // //       postData.push({ ...doc.data(), id: doc.id })
      // //     );
      // //     setData(postData);
      // //     console.log(datas); // <------
      // //   });
      // return;
      // axios
      //   .get("http://localhost:5001/mov365-6d8b8/us-central1/api/users/", {
      //     headers: {
      //       Authorization: "Bearer " + idToken, //the token is a variable which holds the token
      //     },
      //   })
      //   .then(function (response) {
      //     //let obj = response;
      //     console.log(response.data);
      //     setData(response.data.users);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   })
      //   .then(function () {
      //     // always executed
      //   });
      // });
      //console.log(token);
      // await
    }
  }

  useEffect(() => {
    try {
      prepareAllListeners();
    } catch (error) {
      console.log("some error occurred.");
    }
    return () => {
      //cleanup
      if (CompaniesListener.current) {
        CompaniesListener.current();
      }
      if (TotalCompaniesListener.current) {
        TotalCompaniesListener();
      }
      if (TotalUsersNumberListener.current) {
        TotalUsersNumberListener();
      }
    };
  }, []);

  async function deleteACompany(licence) {
    if (licence === "") {
      return;
    }

    try {
      setRemoveOrUpdateCompanyState(_removing);
      console.log("removingCompany ", licence);

      await app
        .auth()
        .currentUser // .getIdToken()
        // .then(function (idToken) {
        .getIdTokenResult()
        .then((idToken) => {
          let url = BACKEND_BASE_URL + "remove_company/";
          //"http://localhost:5000/mov365-6d8b8/us-central1/api/remove_company";

          console.log("remove_company");
          const body = {
            licence: licence,
          };

          axios
            .post(url, body, {
              headers: {
                Authorization: "Bearer " + idToken.token,
                // 'Content-Type' : 'text/plain'
              },
            })
            .then(function (response) {
              //let obj = response;
              console.log(response.data);
              setRemoveOrUpdateCompanyState(_removed);
              setRemoveOrUpdateCompanyMsg(
                JSON.stringify(response.data.message)
              );
            })
            .catch(function (error) {
              console.log(error);

              setRemoveOrUpdateCompanyMsg(JSON.stringify(error.message));
              setRemoveOrUpdateCompanyState(_removed);
            })
            .then(function () {
              // always executed
              setRemoveOrUpdateCompanyState(_removed);
            });
        });
    } catch (error) {
      alert(error);
    }
  }

  async function updateACompany(licence, UserAllowance, OpearationalTime) {
    if (
      licence === "" ||
      UserAllowance === undefined ||
      OpearationalTime === undefined
    ) {
      console.log("update_company error");

      return;
    }
    try {
      setRemoveOrUpdateCompanyState(_updating);
      console.log("updateACompany ", licence);

      await app
        .auth()
        .currentUser // .getIdToken()
        // .then(function (idToken) {
        .getIdTokenResult()
        .then((idToken) => {
          let url = BACKEND_BASE_URL + "update_company/";
          // "http://localhost:5000/mov365-6d8b8/us-central1/api/update_company";

          console.log("update_company");
          const body = {
            Licence: licence,
            UserAllowance,
            OpearationalTime,
          };

          axios
            .post(url, body, {
              headers: {
                Authorization: "Bearer " + idToken.token,
                // 'Content-Type' : 'text/plain'
              },
            })
            .then(function (response) {
              //let obj = response;
              console.log(response.data);
              setRemoveOrUpdateCompanyState(_updated);
              setRemoveOrUpdateCompanyMsg(
                JSON.stringify(response.data.message)
              );
            })
            .catch(function (error) {
              console.log(error);

              setRemoveOrUpdateCompanyMsg(JSON.stringify(error.message));
              setRemoveOrUpdateCompanyState(_updated);
            })
            .then(function () {
              // always executed
              setRemoveOrUpdateCompanyState(_updated);
            });
        });
    } catch (error) {
      alert(error);
    }
  }
  function signOut() {
    app.auth().signOut();
  }

  function toggleShowChat() {
    setShowChat(!ShowChat);
  }
  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    setLocale(checked ? "ar" : "en");
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  //
  const [isOpen, toggle] = useState(false);

  const handleModal = () => {
    //console.log("modal button pressed.");
    toggle(!isOpen);
  };

  // const showMainView = () => {
  //   ShowMainView === _MainDashBoard
  //     ? setShowMainView(_Companies)
  //     : setShowMainView(_MainDashBoard);

  //   // setShowMainView(ShowMainView);
  // };

  const renderMainView = (param) => {
    switch (param) {
      case _MainDashBoard:
        return (
          <MainMasterAdmin
            image={image}
            toggled={toggled}
            toggle={toggle}
            collapsed={collapsed}
            rtl={rtl}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            handleRtlChange={handleRtlChange}
            handleImageChange={handleImageChange}
            handleModal={handleModal}
            isOpen={isOpen}
            setShowMainView={setShowMainView}
            TotalUsersNumber={TotalUsersNumber}
            TotalCompanies={TotalCompanies}
            TotalCompaniesRemoved={TotalCompaniesRemoved}
            TotalUsersRemoved={TotalUsersRemoved}
            ShowChat={ShowChat}
          />
        );
      case _Companies:
        return (
          <CompanyMangement
            AllCompaniesData={AllCompaniesData}
            handleClose={handleClose}
            handleShow={handleShow}
            handleToggleSidebar={handleToggleSidebar}
            show={show}
            removeOrUpdateCompanyMsg={removeOrUpdateCompanyMsg}
            removeOrUpdateCompany={removeOrUpdateCompany}
            deleteACompany={deleteACompany}
            setRemoveOrUpdateCompanyState={setRemoveOrUpdateCompanyState}
            updateValid={updateValid}
            setUpdateValid={setUpdateValid}
            updateACompany={updateACompany}
          />
        );
      default:
        return <div>Some error occurred.</div>;
    }
  };

  return (
    <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
      <AsideMasterAdmin
        image={image}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCreateUserClick={handleModal}
        LogOut={signOut}
        setShowMainView={setShowMainView}
        toggleShowChat={toggleShowChat}
      />
      {renderMainView(ShowMainView)}
    </div>
  );
}

export default MasterAdminDashBoard;
