import React, { Component } from "react";
import { FormErrors } from "./CompanyModification/FormErrors";
import Fade from "react-reveal/Fade";
import Generator from "license-key-generator";

const options = {
  type: "random", // default "random"
  length: 16, // default 16
  group: 4, // default 4
  split: "-", // default "-"
  splitStatus: true, // default true
};
const code = new Generator(options);
const divStyle = `{
  display: flex;
  border: 1px solid blue;
  padding: 4px;
  flex-flow: row wrap;
}`;
class CreateNewCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanyName: "",
      AdminEmail: "",
      password: "123456",
      userName: "",
      Licence: "",
      UserAllowance: 10,
      OpearationalTime: 30,
      formErrors: {
        CompanyName: "",
        userName: "",
        AdminEmail: "",
        password: "",
        Licence: "",
        UserAllowance: 0,
        OpearationalTime: 0,
      },
      CompanyNameValid: false,
      AdminEmailValid: false,
      userNameValid: false,
      LicenceValid: false,
      passwordValid: false,
      formValid: false,
      UserAllowanceValid: false,
      OpearationalTimeValid: false,
    };
  }

  generateLicence = (e) => {
    code.get((error, code) => {
      if (error) return console.error(error);
      // console.log("code=",code);
      this.setState({ Licence: code });
      this.validateField("Licence", code);
    });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let CompanyNameValid = this.state.CompanyNameValid;
    let AdminEmailValid = this.state.AdminEmailValid;
    let userNameValid = this.state.userNameValid;
    let LicenceValid = this.state.LicenceValid;
    let passwordValid = this.state.passwordValid;
    let OpearationalTimeValid = this.state.OpearationalTimeValid;
    let UserAllowanceValid = this.state.UserAllowanceValid;

    switch (fieldName) {
      case "AdminEmail":
        AdminEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.AdminEmail = AdminEmailValid ? "" : " is invalid";
        break;
      case "Licence":
        LicenceValid = value.length >= 8;
        fieldValidationErrors.Licence = LicenceValid ? "" : " is too short";
        break;
      case "CompanyName":
        CompanyNameValid = value.match(
          /^[a-zA-Z0-9]([._](?![._])|[a-zA-Z0-9]){2,18}[a-zA-Z0-9]$/i
        );
        fieldValidationErrors.CompanyName = CompanyNameValid
          ? ""
          : " has invalid character or is too short";
        break;
      case "userName":
        userNameValid = value.match(
          /^[a-zA-Z0-9]([._](?![._])|[a-zA-Z0-9]){2,18}[a-zA-Z0-9]$/i
        );
        fieldValidationErrors.userName = userNameValid
          ? ""
          : " has invalid character or is too short";
        break;
      case "password":
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? "" : " is too short";
        break;
      case "UserAllowance":
        UserAllowanceValid = value >= 10;
        fieldValidationErrors.UserAllowance = UserAllowanceValid
          ? ""
          : " minimum 10 users";
        break;
      case "OpearationalTime":
        OpearationalTimeValid = value >= 30;
        fieldValidationErrors.OpearationalTime = OpearationalTimeValid
          ? ""
          : " minimum 1 month";
        break;
      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        CompanyNameValid: CompanyNameValid,
        AdminEmailValid: AdminEmailValid,
        userNameValid: userNameValid,
        passwordValid: passwordValid,
        LicenceValid: LicenceValid,
        UserAllowanceValid: UserAllowanceValid,
        OpearationalTimeValid: OpearationalTimeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.AdminEmailValid &&
        this.state.userNameValid &&
        this.state.LicenceValid &&
        this.state.passwordValid &&
        this.state.CompanyNameValid &&
        this.state.UserAllowanceValid &&
        this.state.OpearationalTimeValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  ShowUI() {
    if (!this.props.createdCompany) {
      return (
        <div className="topDiv">
          <form className="UserForm " onSubmit={this.props.onSubmit}>
            <h2></h2>
            <div className="panel panel-default insideDiv">
              <div
                className="invalid-feedback"
                //Boostrap 4 uses some CSS tricks to simplify
                //error handling but we're doing it differently
                //so the next line disables these tricks
                style={{ display: "block" }}
              >
                <Fade bottom when={!this.state.formValid}>
                  <FormErrors formErrors={this.state.formErrors} />
                </Fade>
              </div>
            </div>

            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.CompanyName
              )}`}
            >
              <label htmlFor="CompanyName">Company Name:</label>
              <input
                type="text"
                required
                className="form-control"
                name="CompanyName"
                placeholder="Company Name"
                value={this.state.CompanyName}
                onChange={this.handleUserInput}
              />
            </div>

            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.AdminEmail
              )}`}
            >
              <label htmlFor="AdminEmail">Admin Email address</label>
              <input
                type="email"
                required
                className="form-control"
                name="AdminEmail"
                placeholder="Admin Email"
                value={this.state.AdminEmail}
                onChange={this.handleUserInput}
              />
            </div>

            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.userName
              )}`}
            >
              <label htmlFor="userName">Company Admin Name</label>
              <input
                type="text"
                required
                className="form-control"
                name="userName"
                placeholder="Admin Name"
                value={this.state.userName}
                onChange={this.handleUserInput}
              />
            </div>
            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.password
              )}`}
            >
              <label htmlFor="password">Give Admin A Password</label>
              <input
                type="text"
                className="form-control"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleUserInput}
              />
            </div>
            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.Licence
              )}`}
            >
              <label htmlFor="Licence">Give Company A Licence</label>
              <input
                type="text"
                className="form-control"
                name="Licence"
                placeholder="Company-Licence"
                value={this.state.Licence}
                onChange={this.handleUserInput}
              />
              <button type="button" onClick={this.generateLicence}>
                Generate Licence
              </button>
            </div>

            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.UserAllowance
              )}`}
            >
              <label htmlFor="UserAllowance">Maximum allowed Users</label>
              <input
                type="number"
                className="form-control"
                name="UserAllowance"
                placeholder="10"
                value={this.state.UserAllowance}
                onChange={this.handleUserInput}
              />
            </div>
            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.OpearationalTime
              )}`}
            >
              <label htmlFor="OpearationalTime">
                Company Licence Valid for days
              </label>
              <input
                type="number"
                className="form-control"
                name="OpearationalTime"
                placeholder="30"
                value={this.state.OpearationalTime}
                onChange={this.handleUserInput}
              />
            </div>

            {/* <div className="form-group">
              <label htmlFor="role">Role</label>
              <select id="role" className="form-control">
                <optgroup label="User" className="greenSelect">
                  <option value="user">User</option>
                </optgroup>
                <optgroup label="Manager" className="orangeSelect">
                  <option value="manager">Manager</option>
                </optgroup>
                <optgroup label="Admin" className="redSelect">
                  <option value="admin">Admin</option>
                </optgroup>
              </select>
            </div> */}
            {this.props.creatingCompany ? (
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!this.state.formValid}
              >
                Creating ...
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!this.state.formValid}
              >
                Create
              </button>
            )}
          </form>
        </div>
      );
    } else
      return (
        <div className="card">
          <h3>Result</h3>
          <div className="card">{this.props.CompanyCreationResult}</div>
          <button
            type="button"
            onClick={() => {
              this.props.toggle(false);
              this.props.resetState();
            }}
          >
            OK
          </button>
        </div>
      );
  }

  render() {
    return this.ShowUI();
  }
}

export default CreateNewCompany;

/*
XMLHttpRequest.handleLoad (http://localhost:3000/static/js/vendors~main.chunk.js:69329:7)","config":{"url":"http://localhost:5000/mov365-6d8b8/us-central1/api/create_company","method":"post","data":"{\"CompanyName\":\"a_company\",\"AdminEmail\":\"AdminEmail4@gmail.com\",\"displayName\":\"AdminName\",\"Licence\":\"4R5F-RWUR-VILV-SL9S\",\"password\":\"123456\"}","headers":{"Accept":"application/json, text/plain, * /*","Content-Type":"application/json;charset=utf-8","Authorization":"Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImY4NDY2MjEyMTQxMjQ4NzUxOWJiZjhlYWQ4ZGZiYjM3ODYwMjk5ZDciLCJ0eXAiOiJKV1QifQ.eyJyb2xlIjoibWFzdGVyX2FkbWluIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL21vdjM2NS02ZDhiOCIsImF1ZCI6Im1vdjM2NS02ZDhiOCIsImF1dGhfdGltZSI6MTYxNjc1OTYzNiwidXNlcl9pZCI6Ikp4TXJWNjdpYzFSRWZNVHB1MmprU0l6dG5LQTMiLCJzdWIiOiJKeE1yVjY3aWMxUkVmTVRwdTJqa1NJenRuS0EzIiwiaWF0IjoxNjE2NzYzMjk3LCJleHAiOjE2MTY3NjY4OTcsImVtYWlsIjoicm9vdEBkb21haW4uY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInJvb3RAZG9tYWluLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.lDBwTQKQSib1ho78kvss8XHSP1lE5FoUF2tL-jNo-oPjE0k9MalHCBga_t4zEbU84hT8g-WC4dI5KtCHszxqD2p6HJ9piDdtoqS5mKYfGc4xkCYJ_ZKvQFi1n608i61Z0ixc_dQRCFmKgBUGjbdSQmIVtCCfqv-qO9IfNhpOlAi5nzXFWLMSoiDFEW49BQyrNFkuwFPT_sCodAQbnVXIqPhjDGN8W9YdoO5u_9MlItaUkRX0ChH5aMCygjTMizAjahJU1wg4M6bYeILzlfx092ZRNcyjUtR6veE0wLFQ2aAQUb8kPEXx1plyyY9ukijpmx_l7w48s3vIg_MXAdSqHQ"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1}}


{"message":"Network Error","name":"Error","stack":"Error: Network Error\n at createError (http://localhost:3000/static/js/vendors~main.chunk.js:69855:15)\n at XMLHttpRequest.handleError (http://localhost:3000/static/js/vendors~main.chunk.js:69349:14)","config":{"url":"http://localhost:5000/mov365-6d8b8/us-central1/api/create_company","method":"post","data":"{\"CompanyName\":\"ABCf\",\"AdminEmail\":\"ABC16169f00776860@GMAIL.com\",\"displayName\":\"ABCuSERf\",\"Licence\":\"JB6L-ZJ9L-J4DQ-AHZE\",\"password\":\"123456f\",\"UserAllowance\":\"15\",\"OpearationalTime\":\"30\"}","headers":{"Accept":"application/json, text/plain, * /*","Content-Type":"application/json;charset=utf-8","Authorization":"Bearer ..-F5dIRCiW9vTvfdoImGWStkdDl3rTrxr9vXeXqQpec3UoegU----YIK-"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1}}
*/
