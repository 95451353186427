import React from "react";
import Avatar from "react-avatar-edit";

function ImageManipulator({ src, setCanUpload, preview, setPreview }) {
  function onClose() {
    // this.setState({ preview: null });
    setPreview(null);
    setCanUpload(false);
  }

  function onCrop(preview) {
    setPreview(preview);
  }

  function onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 510000) {
      alert("File is too big! 500KB Max.");
      elem.target.value = "";
      setCanUpload(false);
    } else {
      setCanUpload(true);
    }
  }

  return (
    <div className="tiny-margin justify-content-md-center">
      <Avatar
        width={390}
        height={295}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
        src={src}
      />

      {preview == null || preview === "" ? (
        ""
      ) : (
        <img src={preview} alt="Preview" />
      )}
    </div>
  );
}

export default ImageManipulator;
