import React, { useEffect, useState } from "react";
import app from "../base";
import { IntlProvider } from "react-intl";
import messages from "../International/messages";

// //
// import NavBar from './components/NavBar/NavBar';
// import DashBoardHeader from './components/DashBoard/DashBoardHeader/dshboardHeader';
// import UserDashBoardHeader from "./components/DashBoard/DashBoardHeader/UserDashBoardHeader";
// import Footer from './components/Footer/Footer';
// import CreateNewUser from './components/Admin/CreateNewUser';
// import Chat from './components/chat/Chat';
import LoadingIndicator from "./components/LoadingScreen/LoadingIndicator";

// import Chart from "./components/ChartManager/Chart";
// import { IntlProvider } from 'react-intl';

// import 'react-pro-sidebar/dist/css/styles.css';

// import Layout from './components/etc/Layout';
// import Aside from './components/etc/Aside';
// import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader } from 'react-pro-sidebar';
// import './index.css';
import AdminDashBoard from "./components/DashBoard/DashboardPages/AdminDashBoard/AdminDashboard";
import UserDashBoard from "./components/DashBoard/DashboardPages/UserDashBoard/UserDashBoard";

import MasterAdminDashBoard from "./components/Admin/MasterAdmin/MasterAdminDashBoard";

import VerificationPrompt from "./components/Verify/VerificationPrompt";

const Home = () => {
  const _AdminDashBoard = "AdminDashBoard",
    _MasterAdminDashBoard = "MasterAdminDashBoard",
    _UserDashBoard = "UserDashBoard",
    _email_verified = "Email_verified";

  const [showAddUser, setShowAddUser] = useState(false);
  const [showDashBoard, setshowMainView] = useState("");
  const [locale, setLocale] = useState("es");
  const [emailAddrMsg, setEmailAddrMsg] = useState("");

  function signOut() {
    app.auth().signOut();
  }
  function VerificationProceed() {
    var user = app.auth().currentUser;

    user
      .sendEmailVerification()
      .then(function () {
        // Email sent.
        const e = user.email;
        const em =
          e.substring(0, 2) + "***" + e.substring(e.length - 5, e.length);
        setEmailAddrMsg("Verification email has been sent to: " + em);
      })
      .catch(function (error) {
        // An error happened.
        setEmailAddrMsg("" + error);
      });
  }

  const [UserAccess, setCurrentUserAccess] = useState("");
  const [pending, setPending] = useState(true);

  const [rtl, setRtl] = useState(false);

  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    app
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        if (!idTokenResult.claims.email_verified) {
          const e = idTokenResult.claims.email;
          const em =
            e.substring(0, 2) + "***" + e.substring(e.length - 5, e.length);
          //console.log(JSON.stringify(idTokenResult));
          // alert("ye " + JSON.stringify(idTokenResult.claims.email_verified));
          setEmailAddrMsg(
            ` 
            You are assigned with email address: ` +
              em +
              ` .
           Your assigned email address requires verification.
              
           Please verify your email. 
           `
          );
          setCurrentUserAccess(_email_verified);
          setPending(false);

          return;
        }

        if (idTokenResult.claims.role === "master_admin") {
          // Show admin UI.
          setCurrentUserAccess(_MasterAdminDashBoard);
          setPending(false);
        } else if (idTokenResult.claims.role === "Admin") {
          // Show regular user UI.
          setCurrentUserAccess(_AdminDashBoard);
          setPending(false);
        } else if (idTokenResult.claims.role === "user") {
          setCurrentUserAccess(_UserDashBoard);
          setPending(false);
        } else {
          setPending(false);
        }
        //set it as you want right now and change it later as security applies.

        // setshowMainView(_MasterAdminDashBoard);
        // setPending(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (pending) {
    return <LoadingIndicator />;
  }

  // const _CreateNewUser = (e) => {
  //   const { email, password, role } = e.target.elements;
  //   try {
  //     // await app
  //     //   .auth()
  //     //   .signInWithEmailAndPassword(email.value, password.value);
  //     // history.push("/");

  //     console.log(email.value + " , " + password.value + " , " + role.value);
  //   } catch (error) {
  //     alert(error);
  //   }
  // };

  //   return (

  //     UserAccess !== "admin" ?

  //   <div >

  //   <NavBar SignPos={
  //     app.auth().currentUser.email
  //   }
  //    userType = "Admin"
  //   AuthCondition={"Sign out"}
  //   AuthAction={signOut}
  //   />

  // {/* <Aside/> */}
  // <div className="container">
  // {/* <ProSidebar>
  //   <Menu iconShape="square">
  //     <MenuItem >Dashboard</MenuItem>
  //     <SubMenu title="Components" >
  //       <MenuItem>Component 1</MenuItem>
  //       <MenuItem>Component 2</MenuItem>
  //     </SubMenu>
  //   </Menu>
  // </ProSidebar> */}
  // <Aside/>
  // {/* <Layout setLocale={setLocale} /> */}
  // <div className="mainContent">
  //   {showAddUser && <CreateNewUser NewUserSubmit = { _CreateNewUser }/>}

  //   <DashBoardHeader showAddUser={showAddUser} onAdd={ ()=> setShowAddUser(!showAddUser)}/>
  //   {/* <Chart/> */}

  //   {/* <Chat/> */}

  //   <Footer/>
  //   </div>
  // {/* </div> */}
  // </div>
  // </div>
  // :

  // <div>
  // <NavBar
  //    userType = "User"
  //  SignPos={
  //   app.auth().currentUser.email
  // }
  // AuthCondition={"Sign out"}
  // AuthAction={signOut}
  // />
  // <h3>You are user.</h3>
  // <UserDashBoardHeader showAddUser={showAddUser} />

  // <Chart/>

  // <Chat/>

  // <Footer/>

  // </div>

  //   );
  // };

  const renderDashBoard = (param) => {
    switch (param) {
      case _MasterAdminDashBoard:
        return <MasterAdminDashBoard locale={locale} setLocale={setLocale} />;
      case _AdminDashBoard:
        return <AdminDashBoard locale={locale} setLocale={setLocale} />;
      case _UserDashBoard:
        return <UserDashBoard setLocale={setLocale} locale={locale} />;
      case _email_verified:
        return (
          <VerificationPrompt
            emailMsg={emailAddrMsg}
            verify={VerificationProceed}
            logOut={signOut}
          />
        );
      default:
        return <LoadingIndicator />;
    }
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div>{renderDashBoard(UserAccess)}</div>
    </IntlProvider>
  );
};
export default Home;

/*
 if (idTokenResult.claims.role === "master_admin") {
          // Show admin UI.
          setCurrentUserAccess(_MasterAdminDashBoard);
          setPending(false);
        } else if (!!idTokenResult.claims.role === "admin") {
          // Show regular user UI.
          setCurrentUserAccess(_AdminDashBoard);
          setPending(false);
        } else {
          setCurrentUserAccess(_UserDashBoard);
          setPending(false);
        }

        {"role":"master_admin","iss":"https://securetoken.google.com/mov365-6d8b8","aud":"mov365-6d8b8","auth_time":1616758471,"user_id":"JxMrV67ic1REfMTpu2jkSIztnKA3","sub":"JxMrV67ic1REfMTpu2jkSIztnKA3","iat":1616758471,"exp":1616762071,"email":"root@domain.com","email_verified":false,"firebase":{"identities":{"email":["root@domain.com"]},"sign_in_provider":"password"}}


        {"token":"","expirationTime":"Tue, 30 Mar 2021 16:17:02 GMT","authTime":"Tue, 30 Mar 2021 15:17:02 GMT",
        "issuedAtTime":"Tue, 30 Mar 2021 15:17:02 GMT","signInProvider":"password","signInSecondFactor":null,
        "claims":{"role":"master_admin","iss":"https://securetoken.google.com/mov365-6d8b8",
        "aud":"mov365-6d8b8","auth_time":1617117422,"user_id":"lr1pKnW5BPSbotazhAAvKJKZ4as2",
        "sub":"lr1pKnW5BPSbotazhAAvKJKZ4as2","iat":1617117422,"exp":1617121022,
        "email":"rifatalmahmud0111@gmail.com",
        "email_verified":true,"firebase":{"identities":{"email":["rifatalmahmud0111@gmail.com"]},"sign_in_provider":"password"}}}

        */
