import React from "react";
import Card from "react-bootstrap/Card";
import { Image } from "react-bootstrap";
//import defaultUserPhoto from "../assets/movlogo.PNG";

import defaultUserPhoto from "../assets/defaultUserProfile.png";
import { useIntl } from "react-intl";

function PresenceCard({ imageURL, presenceCondition, name }) {
  const intl = useIntl();

  const status =
    presenceCondition == null || presenceCondition === ""
      ? "Unknown"
      : presenceCondition;

  const classNames = [
    "avatar_img d-flex justify-content-center ",
    presenceCondition === "Available" ? "avatar_wrapper_available" : "",
    presenceCondition === "Busy" ? "avatar_wrapper_busy" : "",
    presenceCondition === "OnCall" ? "avatar_wrapper_OnACall" : "",
    presenceCondition === "" || presenceCondition === "Unknown"
      ? "avatar_wrapper_unknown"
      : "",
  ]
    .filter((e) => e)
    .join(" ");
  const classNamesDiv = [
    "border text-center avatar_wrapper  ",
    presenceCondition === "Available" ? "avatar_wrapper_available" : "",
    presenceCondition === "Busy" ? "avatar_wrapper_busy" : "",
    presenceCondition === "OnCall" ? "avatar_wrapper_OnACall" : "",
    presenceCondition === "" || presenceCondition === "Unknown"
      ? "avatar_wrapper_unknown"
      : "",
  ]
    .filter((e) => e)
    .join(" ");

  return (
    <Card style={{ width: "18rem", margin: "1rem" }}>
      <Card.Body>
        <div className="d-flex justify-content-center">
          <div className={classNames}>
            {imageURL === undefined || imageURL === "" ? (
              <Image src={defaultUserPhoto} className="user_header_avatar" />
            ) : (
              // <img src={imageURL} className="user_header_avatar" />
              <Image src={imageURL} className="user_header_avatar" />
            )}
          </div>
        </div>
        <div className={classNamesDiv}>{name}</div>
        <div className="text-center">
          {intl.formatMessage({
            id: status,
          })}
        </div>
      </Card.Body>
    </Card>
  );
}

export default PresenceCard;

//"https://picsum.photos/50/50"
