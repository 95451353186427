import React from "react";
import { Card, Container, Row } from "react-bootstrap";

function DashBoardHeader({
  TotalCompanies,
  TotalUsersNumber,
  TotalCompaniesRemoved,
  TotalUsersRemoved,
}) {
  return (
    <Container fluid className="bg-primary extra-padding">
      <Container fluid>
        <Row className="justify-content-md-center">
          <Card className="col-xl-3 col-md-4">
            <Card.Body>
              <h6 className="card-title text-uppercase text-muted mb-0">
                Total companies created
              </h6>
              <span className="h2 font-weight-bold mb-0">{TotalCompanies}</span>
            </Card.Body>
          </Card>

          <Card className="col-xl-3 col-md-4 ">
            <Card.Body>
              <h6 className="card-title text-uppercase text-muted mb-0">
                Total users created
              </h6>
              <span className="h2 font-weight-bold mb-0">
                {TotalUsersNumber}
              </span>

              <p className="mt-3 mb-0 text-sm">
                <span className="text-nowrap">
                  <small>Across the companies</small>
                </span>
              </p>
            </Card.Body>
          </Card>

          <Card className="col-xl-3 col-md-4 ">
            <Card.Body>
              <h6 className="card-title text-uppercase text-muted mb-0">
                Total companies removed
              </h6>
              <span className="h2 font-weight-bold mb-0">
                {TotalCompaniesRemoved}
              </span>
            </Card.Body>
          </Card>

          <Card className="col-xl-3 col-md-4 ">
            <Card.Body>
              <h6 className="card-title text-uppercase text-muted mb-0">
                Total users removed
              </h6>
              <span className="h2 font-weight-bold mb-0">
                {TotalUsersRemoved}
              </span>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-nowrap">
                  <small>Across the companies</small>
                </span>
              </p>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </Container>
  );
}

export default DashBoardHeader;
