import React from 'react'
import {ProgressBar} from 'react-bootstrap'

function LoadingIndicator() {
    return (
        <div>
            <h3 className="container center">Please wait till we log you in...</h3>
            <ProgressBar animated now={100} />
        </div>
    )
}

export default LoadingIndicator
