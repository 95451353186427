import React from "react";
import { useIntl } from "react-intl";
// import Switch from "react-switch";
import { FaBars } from "react-icons/fa";
// import reactLogo from "./assets/logo.svg";
//
import DashBoardHeader from "../DashBoard/DashBoardHeader/UserDashBoardHeader";
// import Chart from "../ChartManager/Chart";
import UserPresence from "../Presence/UserPresenceUser";
// import PresenceCard from "../Presence/PresenceCard";

// import Modal from "../Admin/UserModification/Modal";
// import OpenModalButton from "../Admin/UserModification/OpenModalButton";
// import styled from "styled-components";
// import CreateNewUser from "../Admin/CreateNewUser";
// import app from "../../../base.js";
// import axios from "axios";
import { Container } from "react-bootstrap";
// import { Card, Button, Container } from "react-bootstrap";

// const ModalContent = styled.div`
//   height: 100%;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   h1 {
//     color: #5c3aff;
//   }
// `;

// const Form = styled.form`
//   width: 100%;
// `;

const MainUser = ({
  /*collapsed,
  rtl,
  image,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  handleModal,
  isOpen,
  currentUsersToTrack,
  toggle,*/
  handleToggleSidebar,
  users,
  CurrentUser,
  usersToShow,
  CurrentDashBoard,
}) => {
  const intl = useIntl();

  return (
    <Container fluid>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>

      <DashBoardHeader CurrentUser={CurrentUser} />

      {/* <Chart /> */}

      {users.length === 0 ? (
        <h3>You are not assigned to a department yet by admin.</h3>
      ) : (
        <h5>
          {intl.formatMessage({ id: "Youbelongto" })}: '{CurrentDashBoard}'{" "}
        </h5>
      )}

      <UserPresence
        // currentUsersToTrack={currentUsersToTrack}
        // dashBoardDepartment={dashBoardDepartment}
        // allUsers={allUsers}
        users={usersToShow}
      />

      {/* <Card>
          <Card.Body>
            <Card.Body>{getElementsToProcess()}</Card.Body>
          </Card.Body>
        </Card> */}
      {/* render this modal at the end so that it renders at a higher order in dashboard*/}

      <footer>
        <small>{intl.formatMessage({ id: "footerCopyRights" })} </small>
        <br />
      </footer>
    </Container>
  );
};

export default MainUser;

/*
     <div>
        <Modal
          resetState={resetState}
          isOpen={isOpen}
          handleClose={handleModal}
        >
          <ModalContent>
            {/* <h1> Awesome modal </h1> * /}
            <div className="card">
              <CreateNewUser
                onSubmit={UserCreation}
                creatingUser={creatingUser}
                createdUser={createdUser}
                UserCreationResult={UserCreationResult}
                resetState={resetState}
                isOpen={isOpen}
                toggle={toggle}
              />
            </div>
          </ModalContent>
        </Modal>
      </div>
*/
