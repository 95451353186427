import React from "react";
import { useIntl } from "react-intl";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { FaTachometerAlt, FaGem, FaHeart, FaPersonBooth } from "react-icons/fa";
import sidebarBg from "./assets/Mov365ProSideLogo.png";
import { DropdownButton, Dropdown } from "react-bootstrap";

const Aside = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleCreateUserClick,
  dash,
  showMainDashBoard,
  showProfile,
  showGraphs,
  LogOut,
  setLocale,
  locale,
}) => {
  const intl = useIntl();

  return (
    <div className="sidebar">
      <ProSidebar
        image={image ? sidebarBg : false}
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div className="center">
            {intl.formatMessage({ id: "sidebarTitleUser" })}

            <DropdownButton
              size="sm"
              variant="link"
              className="righty"
              title={locale}
            >
              <Dropdown.Item onClick={() => setLocale("en")}>en</Dropdown.Item>
              <Dropdown.Item onClick={() => setLocale("es")}>es</Dropdown.Item>
            </DropdownButton>
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem
              icon={<FaTachometerAlt />}
              suffix={
                <span className="badge gray">
                  {intl.formatMessage({ id: "new" })}
                </span>
              }
              onClick={showMainDashBoard}
            >
              {intl.formatMessage({ id: "dashboard" })}
            </MenuItem>
            {/* <MenuItem icon={<FaPersonBooth />} onClick={showGraphs}>
              {intl.formatMessage({ id: "GraphsPresentation" })}
            </MenuItem> */}
          </Menu>
          <Menu iconShape="circle">
            <SubMenu
              title={intl.formatMessage({ id: "Account" })}
              icon={<FaHeart />}
            >
              <MenuItem icon={<FaPersonBooth />} onClick={showProfile}>
                {intl.formatMessage({ id: "Profile" })}
              </MenuItem>
              <MenuItem onClick={LogOut}>
                {intl.formatMessage({ id: "LogOut" })}
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: "center" }}>
          <div>
            <FaGem />
            <span> {intl.formatMessage({ id: "footerCopyRights" })}</span>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default Aside;
