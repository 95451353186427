import React, { useState, useRef } from "react";
import { Container, Modal, Form, Button, Card } from "react-bootstrap";
// import EditProfile from "./EditProfile";
import Accordion from "react-bootstrap/Accordion";
import defaultUserPhoto from "../assets/defaultUserProfile.png";
// import AvatarEditor from "react-avatar-editor";
// import Dropzone from "react-dropzone";

import ImageManipulator from "./imageManipulator/ImageManipulator";

import app, {
  _storage,
  Storage_TaskEvent,
  firestore,
  EmailAuthProvider,
} from "../../../base.js";
import { FaBars } from "react-icons/fa";

// import ProfilePicture from "profile-picture";
// import "profile-picture/build/ProfilePicture.css";

function Profile({
  handleToggleSidebar,
  /*handleCollapsedChange,
  handleRtlChange,
  isOpen,
  handleModal,*/
  OwnSelf,
  OwnSelfStats,
}) {
  // const user = OwnSelf.map((user) => allUsers.find((obj) => obj.id == user));
  const [show, setShow] = useState(false);
  const profilePictureRef = useRef(null);

  const handleClose = () => {
    setShow(false);
    resetProfilePage();
  };
  const handleShow = () => setShow(true);
  const [passwordMatched, setPasswordMatched] = useState(false);
  const passwordGiven = useRef("");
  const currentPassword = useRef("");
  const [passwordValid, setPasswordValid] = useState(true);
  // const [passwordUpdateMessage, setPasswordUpdateMessage] = useState("");
  const [UpdateState, setUpdateState] = useState("");
  // const editorRef = useRef(null);

  // const [image, setimage] = useState(
  //   OwnSelf.photoURL === undefined || OwnSelf.photoURL === ""
  //     ? defaultUserPhoto
  //     : OwnSelf.photoURL
  // );
  const [src, setSrc] = useState(
    OwnSelf.photoURL === undefined || OwnSelf.photoURL === ""
      ? defaultUserPhoto
      : OwnSelf.photoURL
  );
  const [preview, setPreview] = useState(null);
  const [canUpload, setCanUpload] = useState(false);

  async function updatePassword() {
    if (passwordMatched && passwordValid) {
      setUpdateState("Updating");

      var user = await app.auth().currentUser;

      // await user
      //   .updatePassword(passwordGiven)
      //   .then(function () {
      //     // Update successful.
      //     setUpdateState("Updated");
      //   })
      //   .catch(function (error) {
      //     // An error happened.
      //     setUpdateState("UpdateFailed");
      //   });

      // setTimeout(() => {
      //   setUpdateState("UpdateFailed");
      // }, 1000);
      //code: "auth/wrong-password"

      console.log(user.email, currentPassword.current.toString());

      // return;
      var cred = await EmailAuthProvider.credential(
        user.email,
        currentPassword.current.toString()
      );

      const result = await user
        .reauthenticateWithCredential(cred)
        .then((cred) => {
          // return;
          user
            .updatePassword(passwordGiven.current.toString())
            .then(() => {
              console.log("Password updated!");
              setUpdateState("Updated");
            })
            .catch((error) => {
              console.log(error);
              setUpdateState("UpdateFailed");
            });
          console.log(cred.user.email);
        });

      console.log("res - ", result);
      // console.log("msg - " + result );
    }
  }

  async function handleUpload() {
    setUpdateState("UploadingImage");
    const PP = profilePictureRef.current;
    if (PP === undefined) {
      return;
    }
    // const imageData = PP.getData();
    // const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();
    if (
      imageAsDataURL ===
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAACzElEQVR4Xu3SMQ0AAAzDsJU/6cHI4xKoFHlnCoQFFn67VuAAhCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5ncOIANpAQDT/M4BZCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5ncOIANpAQDT/M4BZCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5ncOIANpAQDT/M4BZCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5ncOIANpAQDT/M4BZCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5ncOIANpAQDT/M4BZCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5ncOIANpAQDT/M4BZCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5ncOIANpAQDT/M4BZCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5ncOIANpAQDT/M4BZCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5ncOIANpAQDT/M4BZCAtAGCa3zmADKQFAEzzOweQgbQAgGl+5wAykBYAMM3vHEAG0gIApvmdA8hAWgDANL9zABlICwCY5nf+kmEAoaOpQZEAAAAASUVORK5CYII="
    ) {
      setUpdateState("ImageUploadedFailed");

      return;
    }

    app
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        let root = idTokenResult.claims.Licence;
        let uid = idTokenResult.claims.user_id;

        // return;

        //add here the upload logic...

        // console.log("uploading with imageAsDataURL: ", imageAsDataURL);

        // Get current username
        //var user = await app.auth().currentUser;

        // Create a Storage Ref w/ username
        var storageRef = _storage.ref(root + "/user/profilePicture/" + uid);

        // Upload file
        // var task = storageRef.put(file);
        // //Update Progress Bar
        // task.on(
        //   "state_changed",

        //   function progress(snapshot) {
        //     var percentage =
        //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //     uploader.value = percentage;

        //     //if percentage = 100
        //     //$(".overlay").hide();
        //   },

        //   function error(err) {},

        //   function complete() {}
        // );

        var uploadTask = storageRef.putString(imageAsDataURL, "data_url", {
          contentType: "image/png",
        });

        // uploadTask.on(
        //   Storage_TaskEvent.STATE_CHANGED, // or 'state_changed'
        //   function (snapshot) {
        //     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        //     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //     console.log("Upload is " + progress + "% done");
        //     switch (snapshot.state) {
        //       case Storage_TaskEvent.PAUSED: // or 'paused'
        //         console.log("Upload is paused");
        //         break;
        //       case Storage_TaskEvent.RUNNING: // or 'running'
        //         console.log("Upload is running");
        //         break;
        //     }
        //   },
        //   function (error) {
        //     console.log(error);
        //   },
        //   function () {
        //     // Upload completed successfully, now we can get the download URL
        //     var downloadURL = uploadTask.snapshot.downloadURL;
        //   }
        // );
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          Storage_TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case Storage_TaskEvent.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case Storage_TaskEvent.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
              default:
                console.log("Unknows upload state");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            setUpdateState("ImageUploadedFailed");
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                console.log("Unauth error");
                break;
              case "storage/canceled":
                // User canceled the upload
                console.log("Canceled");
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                console.log("Unknown error");
                break;
              default:
                console.log("Unknows upload error");
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);

              var usersRef = firestore
                .collection("Companies")
                .doc(root)
                .collection("Users")
                .doc(uid);

              usersRef
                .update({
                  photoURL: downloadURL,
                })
                .then(function () {
                  // Update successful.
                  setUpdateState("ImageUploaded");
                })
                .catch(function (error) {
                  // An error happened.
                  setUpdateState("ImageUploadedFailed");
                });

              // user
              //   .updateProfile({
              //     // displayName: "Jane Q. User",
              //     photoURL: downloadURL,
              //   })
              //   .then(function () {
              //     // Update successful.
              //     setUpdateState("ImageUploaded");
              //   })
              //   .catch(function (error) {
              //     // An error happened.
              //     setUpdateState("ImageUploadedFailed");
              //   });
            });
          }
        );
        console.log("this user got this root: ", root);
      });
  }

  async function handleUploadV2() {
    if (preview && canUpload) {
      setUpdateState("UploadingImage");

      app
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          let root = idTokenResult.claims.Licence;
          let uid = idTokenResult.claims.user_id;

          // return;

          //add here the upload logic...

          // Create a Storage Ref w/ username
          var storageRef = _storage.ref(root + "/user/profilePicture/" + uid);

          var uploadTask = storageRef.putString(preview, "data_url");

          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on(
            Storage_TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case Storage_TaskEvent.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case Storage_TaskEvent.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
                default:
                  console.log("Unknows upload state");
                  break;
              }
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              setUpdateState("ImageUploadedFailed");
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  console.log("Unauth error");
                  break;
                case "storage/canceled":
                  // User canceled the upload
                  console.log("Canceled");
                  break;

                // ...

                case "storage/unknown":
                  // Unknown error occurred, inspect error.serverResponse
                  console.log("Unknown error");
                  break;
                default:
                  console.log("Unknows upload error");
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                //console.log("File available at", downloadURL);

                var usersRef = firestore
                  .collection("Companies")
                  .doc(root)
                  .collection("Users")
                  .doc(uid);

                usersRef
                  .update({
                    photoURL: downloadURL,
                  })
                  .then(function () {
                    // Update successful.
                    setUpdateState("ImageUploaded");
                    setSrc(downloadURL);
                  })
                  .catch(function (error) {
                    // An error happened.
                    setUpdateState("ImageUploadedFailed");
                  });
              });
            }
          );
          //console.log("this user got this root: ", root);
        });
    }
  }

  function resetProfilePage() {
    setPasswordMatched(false);
    setPasswordValid(false);
    passwordGiven.current = "";
    currentPassword.current = "";
    setUpdateState("");
  }

  function getUpdatePasswordForm() {
    switch (UpdateState) {
      case "Updating":
        return (
          <Modal show={show} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Edit profile Image or Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>Updating Password...</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary">Updating...</Button>
            </Modal.Footer>
          </Modal>
        );

      case "Updated":
        return (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit profile Image or Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>Password has been updated successfully.</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                onClick={() => {
                  handleClose();
                  resetProfilePage();
                }}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case "UpdateFailed":
        return (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit profile Image or Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>Failed to update password.</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="warning"
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case "UploadingImage":
        return (
          <Modal show={show} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Edit profile Image or Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>Uploading Image...</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary">...</Button>
            </Modal.Footer>
          </Modal>
        );

      case "ImageUploaded":
        return (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit profile Image or Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>Uploaded Image.</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                onClick={() => {
                  handleClose();
                }}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case "ImageUploadedFailed":
        return (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit profile Image or Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>Failed Uploading Image.</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="warning"
                onClick={() => {
                  handleClose();
                }}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        );
      default:
        return (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit profile Image or Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="block" eventKey="0">
                      Change Password
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Form>
                        <Form.Group>
                          {/* <Form.Label>Department name</Form.Label> */}

                          <Form.Control
                            type="password"
                            placeholder="Type Your current Password"
                            onChange={(e) => {
                              if (
                                e.target.value.length > 5 &&
                                e.target.value.length < 49
                              ) {
                                setPasswordValid(true);
                              } else {
                                setPasswordValid(false);
                              }
                              currentPassword.current = e.target.value;
                            }}
                          />
                          <p></p>
                          <Form.Control
                            type="password"
                            placeholder="Type Your New Password"
                            onChange={(e) => {
                              if (
                                e.target.value.length > 5 &&
                                e.target.value.length < 49
                              ) {
                                setPasswordValid(true);
                              } else {
                                setPasswordValid(false);
                              }
                              passwordGiven.current = e.target.value;
                            }}
                          />
                          <p></p>
                          <Form.Control
                            type="password"
                            placeholder="Confirm Your Password"
                            onChange={(e) => {
                              // setDeptName(e.target.value);
                              if (e.target.value === passwordGiven.current) {
                                setPasswordMatched(true);
                              } else {
                                setPasswordMatched(false);
                              }
                            }}
                          />
                          <Form.Text className="text-muted">
                            {passwordMatched ? (
                              <p className="border border-success">
                                Password matched.
                              </p>
                            ) : (
                              ""
                            )}

                            {passwordValid ? (
                              ""
                            ) : (
                              <p className="border border-warning">
                                Password is not valid. Minimum 6 characters.
                              </p>
                            )}
                          </Form.Text>
                        </Form.Group>
                        {/* {departmentModifying ? (
                        <Button variant="btn" type="button">
                          Updating...
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          type="button"
                          onClick={() => {
                            console.log(deptName);
                            //ModifyDepartment(true, deptName);
                          }}
                        >
                          Add
                        </Button>
                      )} */}
                      </Form>
                      {passwordValid && passwordMatched ? (
                        <Button
                          variant="primary"
                          onClick={() => updatePassword()}
                        >
                          Update password
                        </Button>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="block" eventKey="0">
                      Change Profile Image
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="justify-content-md-center">
                      {/* <Form>
                        <Form.Group controlId="formBasicText">
                          {/* <Form.Label>Department name</Form.Label> * /}
                          <Form.Control
                            type="password"
                            placeholder="Type Your New Password"
                            onChange={(e) => {}}
                          />
                         
                          <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                      </Form> */}
                      {/* <ProfilePicture
                        ref={profilePictureRef}
                        useHelper={true}
                        debug={true}
                      /> */}
                      {/* <p>Please drag and drop new image.</p> */}
                      {/* <Dropzone
                        onDrop={handleDrop}
                        noClick
                        noKeyboard
                        style={{ width: "260px", height: "260px" }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <AvatarEditor
                              ref={setEditorRef}
                              width={260}
                              height={260}
                              image={image}
                            />
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </Dropzone> */}

                      <ImageManipulator
                        src={src}
                        setSrc={setSrc}
                        preview={preview}
                        setPreview={setPreview}
                        setCanUpload={setCanUpload}
                      />

                      <Button
                        variant={
                          preview == null ||
                          preview === "" ||
                          canUpload === false
                            ? "secondary"
                            : "success"
                        }
                        onClick={handleUploadV2}
                      >
                        Upload
                      </Button>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        );
    }
  }

  //async function getPhotoURL() {}
  return (
    <Container>
      <div className="row py-5 px-4">
        <div className="col-xl-6 col-md-8 col-sm-10 mx-auto">
          {" "}
          <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
            <FaBars />
          </div>
          <div className="bg-white shadow rounded overflow-hidden">
            <div className="px-4 pt-0 pb-4 bg-dark">
              <div className="media align-items-end profile-header">
                <div className="profile mr-3">
                  {OwnSelf.photoURL === undefined || OwnSelf.photoURL === "" ? (
                    <img
                      src={defaultUserPhoto}
                      alt="..."
                      width="130"
                      className="rounded mb-2 img-thumbnail"
                    />
                  ) : (
                    <img
                      src={OwnSelf.photoURL}
                      alt="...OwnSelf.photoURL"
                      width="130"
                      className="rounded mb-2 img-thumbnail"
                    />
                  )}
                  <button
                    className="btn btn-dark btn-sm btn-block "
                    onClick={handleShow}
                  >
                    <span className=" border-secondary border-bottom">
                      Edit
                    </span>
                  </button>
                </div>
                <div className="media-body mb-5 text-white">
                  <h4 className="mt-0 mb-0">{OwnSelf.displayName}</h4>
                  <div className="small mb-4">
                    <i className="fa fa-map-marker mr-2"></i>
                    {OwnSelf.email}
                    <div className="fa fa-map-marker mr-2"></div>
                    {OwnSelf.phone ? "  PH: " + OwnSelf.phone : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-light p-4 d-flex justify-content-end text-center"></div>

            <div className="py-4 px-4">
              <div className="d-flex align-items-center justify-content-between mb-4 ">
                <h5 className="mb-0 border-bottom">Stats</h5>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-2 pr-lg-1">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <h5 className="font-weight-normal mb-0 d-block">
                        Total Minutes
                      </h5>
                      <h5 className="font-weight-bold mb-0 d-block">
                        {OwnSelfStats.totalMinute}
                      </h5>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 mb-2 pr-lg-1">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <h5 className=" mb-0 d-block">Total IncomingCalls</h5>
                      <h5 className="font-weight-bold mb-0 d-block">
                        {OwnSelfStats.totalIncomingCalls}
                      </h5>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 mb-2 pr-lg-1">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <h5 className=" mb-0 d-block">Total OutGoingCalls</h5>
                      <h5 className="font-weight-bold mb-0 d-block">
                        {OwnSelfStats.totalOutGoingCalls}
                      </h5>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 mb-2 pr-lg-1">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <h5 className="  mb-0 d-block">Total MissedCalls</h5>
                      <h5 className="font-weight-bold mb-0 d-block">
                        {OwnSelfStats.totalMissedCalls}
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{getUpdatePasswordForm()}</div>
    </Container>
  );
}

export default Profile;

/*
{"current":{"totalOutGoingCalls":0,"email":"xobij71143@684hh.com","department":"","uid":"qqGYSz3Gu6VRosFqaGoxPW8MNOx2","totalMinute":0,"totalMissedCalls":0,"IsOnline":false,"totalIncomingCalls":10,"displayName":"ABCuSERd"}}

*/
