import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { Calendar } from "react-date-range";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import { useState, useRef } from "react";
import { firestore } from "../../../../../../base";
import { FaBars, FaCalendar } from "react-icons/fa";
import AdvancedDataTable from "./AdvancedDataTable";

function AdvancedDataPresentation({
  handleToggleSidebar,
  currentDocRoot,
  allUsers,
  dashBoardDepartment,
  currentUsersToTrack,
}) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: subDays(new Date(), 1),
      key: "selection",
    },
  ]);

  function handleSelect(x) {
    // console.log(x); // native Date object
    // console.log(x.selection.endDate); // native Date object
    // console.log("state is " + state.endDate);
    // console.log("date is " + Date());
    // console.log("date - is " + new Date("2021 - 07 - 19"));

    //setDate(x.selection.endDate + "");

    date.current = [x.selection.endDate, x.selection.startDate];

    // console.log(
    //   "endDate is " + date.current[0] + " ,StartDate: " + date.current[1]
    // );
    // console.log("timestamp is " + timestamp);
  }

  const LogOnSelectedData = useRef(new Array());
  const [logsOnSelectedData, setLogsOnSelectedData] = useState(new Array());
  // const [open, setopen] = useState(false);
  const [a, setA] = useState("divx2");
  const [filtering, setFiltering] = useState(false);
  const [filteringMSG, setFilteringMSG] = useState("");
  const date = useRef([new Date(), subDays(new Date(), 1)]);
  //let timestamp = new Date("2021-07-18");

  function queryProcess(params) {
    // Find all dinosaurs whose height is exactly 25 meters.
    if (currentDocRoot === null || currentUsersToTrack === null) {
      return;
    }

    setFiltering(true);
    // console.log(
    //   "OK. date.current[0] - ",
    //   date.current[0]
    //   // " , ts- ",
    //   // timestamp,
    //   // " , ts1- "
    //   // timestamp1
    // );

    LogOnSelectedData.current.length = 0;
    var len = currentUsersToTrack.length - 1;
    setFilteringMSG("Fetching Data");
    currentUsersToTrack.forEach((element, index) => {
      var parentRef = firestore
        .collection("Companies")
        .doc(currentDocRoot)
        .collection("Users")
        .doc(element)
        .collection("Logs");
      //.doc("logs");

      //console.log("element...", element);

      // if (len === index) {
      //   console.log("last index...");
      //   //setLogsOnSelectedData([...LogOnSelectedData.current]);
      //   console.log(
      //     "LogOnSelectedData.current end- " +
      //       JSON.stringify(LogOnSelectedData.current)
      //   );
      // }
      // return;
      // LogOnSelectedData.current = [...LogOnSelectedData.current, 1, 2];
      // if (currentUsersToTrack.length - 1 === i) {
      //   console.log("last index...");
      //   setLogsOnSelectedData([...LogOnSelectedData.current])
      // }
      // console.log(
      //   "LogOnSelectedData.current - " +
      //     JSON.stringify(LogOnSelectedData.current)
      // );
      // return;
      parentRef
        .where("date", ">=", date.current[1])
        .where("date", "<=", date.current[0])
        // .where("type", "==", "OutgoingEnd")
        .orderBy("date")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            if (doc.exists) {
              var thisObj = doc.data();
              var name = "";
              try {
                // var name = allUsers[element].displayName;
                var foundObject = allUsers.reduce(function (prev, curr) {
                  return curr.uid === element ? curr : prev;
                }, "null");
                name = foundObject.displayName;
                //console.log("foundObject - " + JSON.stringify(foundObject));
              } catch (error) {}

              thisObj.date = thisObj.date.toDate() + "";
              var obj = { id: doc.id, UName: name, ...thisObj };

              LogOnSelectedData.current = [...LogOnSelectedData.current, obj];
              // console.log(
              //   "LogOnSelectedData.current - " + LogOnSelectedData.current
              // );
            }
          });
          if (len === index) {
            sortAndSet();
            // console.log(
            //   "LogOnSelectedData.current end- " +
            //     JSON.stringify(LogOnSelectedData.current)
            // );
          }
        });
    });
  }

  function sortAndSet(params) {
    // console.log("last index...");
    setFilteringMSG("Sorting");
    var sorted = LogOnSelectedData.current.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return c - d;
    });
    setFilteringMSG("");
    setLogsOnSelectedData([...sorted]);
    LogOnSelectedData.current.length = 0;
    setFiltering(false);
  }

  function changeA(params) {
    a === "divx" ? setA("divx1") : setA("divx");
  }

  function sortUp(params) {
    if (logsOnSelectedData == null) {
      return;
    }
    setFiltering(true);

    setFilteringMSG("Sorting");
    var sorted = logsOnSelectedData.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return c - d;
    });
    setFilteringMSG("");
    setLogsOnSelectedData([...sorted]);
    setFiltering(false);
  }

  function sortDown(params) {
    if (logsOnSelectedData == null) {
      return;
    }
    setFiltering(true);

    setFilteringMSG("Sorting");
    var sorted = logsOnSelectedData.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return d - c;
    });
    setFilteringMSG("");
    setLogsOnSelectedData([...sorted]);
    setFiltering(false);
  }

  return (
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ">
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary  "
          onClick={changeA}
        >
          <span>
            <FaCalendar />
          </span>
          {" Range: "}
          {date.current[1].toDateString() +
            " - " +
            date.current[0].toDateString()}
        </button>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary"
              onClick={queryProcess}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className={a}>
        <DateRangePicker
          onChange={(item) => {
            setState([item.selection]);
            handleSelect(item);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          direction="horizontal"
        />
      </div>
      <div className="border-bottom"></div>
      {/* <div>User, Date, Time, Type, Number, Duration</div> */}

      {filtering === true ? (
        <div>
          <p className="text-center">Filtering... ( {filteringMSG} )</p>
        </div>
      ) : (
        <AdvancedDataTable
          logsOnSelectedData={logsOnSelectedData}
          dashBoardDepartment={dashBoardDepartment}
          sortUp={sortUp}
          sortDown={sortDown}
        />
      )}
    </main>
  );
}

export default AdvancedDataPresentation;

//

//var citiesRef = firestore.collection("cities");

// citiesRef
//   .where("population", "<", 1000000)
//   .where("population", ">", 680000)
//   // .where("country", "==", "China")
//   .get()
//   .then((querySnapshot) => {
//     querySnapshot.forEach((doc) => {
//       console.log(doc.data());
//     });
//   });

// var museums = firestore
//   .collectionGroup("landmarks")
//   .where("type", "==", "museum");
// museums.get().then((querySnapshot) => {
//   querySnapshot.forEach((doc) => {
//     console.log(doc.id, " => ", doc.data());
//   });
// });

// var ref = firestore
//   .collectionGroup("Users")
//   .where("totalIncomingCalls", ">=", 1);

// ref.get().then(function (querySnapshot) {
//   // Do something
//   querySnapshot.docs.forEach((doc) => {
//     console.log(JSON.stringify(doc.data()));
//   });
// });

// //   .collection("Users");

// console.log(ref);

// ref
//   .where("displayName", "==", "ABCuSERd")
//   .get()
//   .then((snap) => {
//     snap.docs.forEach((doc) => {
//       console.log(JSON.stringify(doc.data()));
//     });
//   });

//ref
// .orderByChild("height")
// .equalTo(25)
//.on("child_added", function (snapshot) {
//  console.log(snapshot.key);
//});

///
/*
{date: t, 
  type: "totalMissedCalls", 
  num: "6505551212"}
  date: t {seconds: 1626415956, nanoseconds: 0}
  num: "6505551212"type: "totalMissedCalls"__proto__: Object
*/
