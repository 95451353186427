import React from "react";
import { useIntl } from "react-intl";

const UserDashBoardHeader = ({ CurrentUser }) => {
  const intl = useIntl();

  return (
    <div className="header bg-primary pb-6">
      <div className="container-fluid extra-padding">
        <div className="header-body">
          <div className="row align-items-center py-4"></div>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        {intl.formatMessage({ id: "YourTotalMinutes" })}
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {CurrentUser.totalMinute}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                        <i className="ni ni-active-40"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        {intl.formatMessage({ id: "YourTotalIncomingCalls" })}
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {CurrentUser.totalIncomingCalls}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                        <i className="ni ni-active-40"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        {intl.formatMessage({ id: "YourTotalOutGoingCalls" })}
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {CurrentUser.totalOutGoingCalls}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                        <i className="ni ni-active-40"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        {intl.formatMessage({ id: "YourTotalMissedCalls" })}
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {CurrentUser.totalMissedCalls}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                        <i className="ni ni-active-40"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashBoardHeader;
