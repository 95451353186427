import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Container,
  Form,
} from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";

function CompanyMangement({
  AllCompaniesData,
  handleClose,
  handleShow,
  show,
  removeOrUpdateCompany,
  handleToggleSidebar,
  removeOrUpdateCompanyMsg,
  deleteACompany,
  setRemoveOrUpdateCompanyState,
  updateValid,
  setUpdateValid,
  updateACompany,
}) {
  const companyLicence = useRef("");
  const NewUserAllowance = useRef(0);
  const companyName = useRef("");
  const NewDaysAllowance = useRef(0);

  const _dangerDeleteCompany = "DangerDeleteCompany",
    _updateCompany = "UpdateCompany",
    _removed = "Removed",
    _removing = "Removing",
    _updating = "Updating",
    _updated = "Updated";

  const [editable, setEditable] = useState(false);
  // const currentLicence = useRef("");
  const [proceedConfirmed, setProceedConfirmed] = useState(false);

  function calculateTime(OpearationalTime) {
    let expired = OpearationalTime - 86400000 <= Date.now();
    return expired ? (
      <Col className="border border-warning">
        Valid till: {new Date(OpearationalTime).toUTCString()} (expired)
      </Col>
    ) : (
      <Col>Valid till: {new Date(OpearationalTime).toUTCString()}</Col>
    );
  }
  function getElementsToShow() {
    //console.log("datas : ", JSON.stringify(AllCompaniesData));

    if (AllCompaniesData.length === 0) {
      console.log("datas.length === 0");
      return (
        <Card>
          <p>No Company Exist Yet.</p>
          <p>
            Please create one by visiting 'Dashboard' and clicking 'Create New
            Compnay' button.
          </p>
        </Card>
      );
    }

    try {
      return (
        <Container fluid>
          <p>
            <label>
              <input
                type="checkbox"
                defaultChecked={editable}
                onChange={() => setEditable(!editable)}
              />
              {" Make Editable"}
            </label>
          </p>
          <div>
            <ol>
              {AllCompaniesData.map((item) => (
                <li key={item.CompanyName}>
                  <Card>
                    <Col>Name of the compnay is: {item.CompanyName}</Col>
                    <Col>Licence is: {item.Licence}</Col>
                    {calculateTime(item.Validity.OpearationalTime)}

                    <Col>User Allowance: {item.Validity.UserAllowance}</Col>
                    <Col> Admin Email: {item.Admin.AdminEmail}</Col>
                    {
                      <Fade left when={editable}>
                        <p></p>
                        <Row className="justify-content-md-center">
                          <Button
                            onClick={() => {
                              if (!editable) {
                                return;
                              }
                              companyLicence.current = item.Licence;
                              companyName.current = item.CompanyName;
                              setRemoveOrUpdateCompanyState(_updateCompany);
                              handleShow();
                            }}
                          >
                            Update Validity
                          </Button>
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          <Button
                            variant="danger"
                            onClick={() => {
                              if (!editable) {
                                return;
                              }
                              companyLicence.current = item.Licence;
                              companyName.current = item.CompanyName;
                              setRemoveOrUpdateCompanyState(
                                _dangerDeleteCompany
                              );
                              setProceedConfirmed(false);

                              handleShow();
                            }}
                          >
                            Remove this company. (Non reversible)
                          </Button>
                        </Row>
                      </Fade>
                    }
                  </Card>
                </li>
              ))}
            </ol>
          </div>
        </Container>
      );
    } catch (error) {
      return (
        <div>
          <p className="card">Some error occurred.</p>
        </div>
      );
    }
  }

  function getModalContent() {
    switch (removeOrUpdateCompany) {
      case _removing:
        return (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Removing {companyName.current}?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Removing company "{companyName.current}". It is your own
              responsibility. Not reversible!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary">Removing...</Button>
            </Modal.Footer>
          </Modal>
        );

      case _removed:
        return (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Removed {companyName.current}!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{removeOrUpdateCompanyMsg}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        );

      case _updating:
        return (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Update validity for: {companyName.current} .
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{removeOrUpdateCompanyMsg}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary">Please wait while processing</Button>
            </Modal.Footer>
          </Modal>
        );
      case _updated:
        return (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Updated {companyName.current}!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{removeOrUpdateCompanyMsg}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleClose}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case _dangerDeleteCompany:
        return (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are you sure you want to delete {companyName.current}?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Removing company "{companyName.current}" will delete all of its
                users and their related data. So it is your own responsibility
                if you want to proceed. Please reconsider your action!
              </p>
              <Form>
                <p></p>
                <Form.Label>Type the name of the company to remove:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Type Company Name"
                  onChange={(e) => {
                    if (e.target.value === companyName.current) {
                      setProceedConfirmed(true);
                      // console.log("Update is valide...");
                    } else {
                      // NewUserAllowance.current = 0;

                      setProceedConfirmed(false);
                    }
                  }}
                />
                <p></p>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              {proceedConfirmed ? (
                <Button
                  variant="danger"
                  onClick={() => deleteACompany(companyLicence.current)}
                >
                  Proceed Anyway
                </Button>
              ) : (
                ""
              )}
            </Modal.Footer>
          </Modal>
        );
      case _updateCompany:
        return (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Company Validity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="block" eventKey="0">
                      Update Time or UserAllowance for '{companyName.current}'
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Form>
                        <Form.Group>
                          <Form.Label>
                            Type number of days you want to allow:
                          </Form.Label>

                          <Form.Control
                            type="number"
                            placeholder="Number of days"
                            onChange={(e) => {
                              let _valid =
                                NewUserAllowance.current > 11 &&
                                NewUserAllowance.current < 1049;
                              if (
                                e.target.value > 29 &&
                                e.target.value < 1025 &&
                                _valid
                              ) {
                                setUpdateValid(true);
                              } else {
                                NewDaysAllowance.current = 0;

                                setUpdateValid(false);
                              }
                              NewDaysAllowance.current = e.target.value;
                            }}
                          />
                          <p></p>
                          <Form.Label>
                            Type total number of users you want to allow:
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Type Number of Users"
                            onChange={(e) => {
                              let _valid =
                                NewDaysAllowance.current > 29 &&
                                NewDaysAllowance.current < 1025;
                              if (
                                e.target.value > 10 &&
                                e.target.value < 1025 &&
                                _valid
                              ) {
                                setUpdateValid(true);
                                console.log("Update is valide...");
                              } else {
                                // NewUserAllowance.current = 0;

                                setUpdateValid(false);
                              }
                              NewUserAllowance.current = e.target.value;
                            }}
                          />
                          <p></p>

                          <Form.Text className="text-muted">
                            {!updateValid ? (
                              <p className="border border-warning">
                                Should be min 30 days and min 11 users.
                              </p>
                            ) : (
                              ""
                            )}
                          </Form.Text>
                        </Form.Group>
                      </Form>
                      {updateValid ? (
                        <Button
                          variant="primary"
                          onClick={() => {
                            updateACompany(
                              companyLicence.current,
                              NewUserAllowance.current,
                              NewDaysAllowance.current
                            );
                            NewUserAllowance.current = 0;
                            NewDaysAllowance.current = 0;
                          }}
                        >
                          Update Company Validity
                        </Button>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        );

      default:
        return (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Something went wrong!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Error</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        );
    }
  }

  return (
    <Container fluid>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <p></p>
      <h3>Your created companies information:</h3>
      {getElementsToShow()}
      {getModalContent()}
      <p></p>

      <footer>This is the MasterAdmin Companies Overview Page.</footer>
    </Container>
  );
}
export default CompanyMangement;
