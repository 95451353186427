import React, { useRef, useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { auth, db } from "../../../base.js";

function ChatAdmins() {
  const user = useRef(auth().currentUser);
  const [chats, setChats] = useState([]);
  const chatLen = useRef(0);
  const [content, setContent] = useState("");
  const [readError, setReadError] = useState(null);
  const [writeError, setwriteError] = useState(null);
  const [buttonActive, setbuttonActive] = useState(true);

  const myRef = useRef();
  const oRef = useRef(null);
  const rtdbRef = useRef(null);

  useEffect(() => {
    setReadError(null);
    try {
      rtdbRef.current = db
        .ref("chats")
        .limitToLast(20)
        .on("value", (snapshot) => {
          let chats1 = [];
          snapshot.forEach((snap) => {
            chats1.push(snap.val());

            //console.log(JSON.stringify(snap.val()));
          });
          let len = chats1.length - 1;
          if (len < 0) {
            len = 0;
          }

          setChats(chats1);

          // setchatLen(len);
          chatLen.current = len;

          setTimeout(() => {
            try {
              if (myRef.current !== null || myRef.current !== undefined) {
                myRef.current.scrollIntoView();
              }
            } catch (error) {
              console.log(error);
            }
          }, 100);
        });
    } catch (error) {
      setReadError(error.message);
    }
    return () => {
      if (rtdbRef.current) {
        // rtdbRef.current.off("value");
      }
    };
  }, []);

  function handleChange(event) {
    // this.setState({
    //   content: event.target.value,
    // });
    setContent(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    console.log(content);
    if (content === "" || !content.trim()) {
      setContent("");

      return;
    }
    setwriteError(null);
    setbuttonActive(false);
    // this.setState({ writeError: null, buttonActive: false });
    try {
      console.log(user.current.displayName);
      await db.ref("chats").push({
        content: content,
        timestamp: Date.now(),
        uid: user.current.uid,
        name: user.current.displayName,
      });
      // this.setState({ content: "" });
      // this.myRef.current.scrollIntoView();
      //   content.current = "";
      setContent("");
      setTimeout(() => {
        // this.setState({ buttonActive: true });
        setbuttonActive(true);
      }, 200);
    } catch (error) {
      // this.setState({ writeError: error.message });
      setwriteError(error.message);
    }
  }

  return (
    <Container>
      <Card>
        <Card.Header>Message room for Admins and MasterAdmin</Card.Header>
        <Card.Body className="limitHight">
          <div className="chats">
            {chats.map((chat, i) => {
              return (
                <Card
                  ref={chatLen.current === i ? myRef : oRef}
                  key={chat.timestamp}
                  className="tiny-margin"
                >
                  <Card.Body>
                    {chat.name ? (
                      <h6>
                        {chat.name}
                        <small> says</small>
                      </h6>
                    ) : (
                      ""
                    )}

                    {chat.content}
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </Card.Body>
        <Card.Footer>
          <div>
            <form onSubmit={handleSubmit}>
              <input onChange={handleChange} value={content}></input>
              {/* {readError || writeError ? (
                <p>
                  {readError}
                  {writeError}
                </p>
              ) : null} */}
              {buttonActive ? (
                <button type="submit">Send</button>
              ) : (
                <button type="button">...</button>
              )}
            </form>
            <div>
              Logged in as: <strong>{user.current.email}</strong>
            </div>
          </div>
        </Card.Footer>
      </Card>
    </Container>
  );
}

export default ChatAdmins;
