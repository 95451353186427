import React, { useState } from "react";
import { useIntl } from "react-intl";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaTachometerAlt,
  // FaGem,
  FaList,
  FaPersonBooth,
  FaVoicemail,
  FaAdjust,
  FaDigitalTachograph,
} from "react-icons/fa";

import { ImProfile } from "react-icons/im";

import sidebarBg from "./assets/Mov365ProSideLogo.png";
import { DropdownButton, Dropdown } from "react-bootstrap";

const Aside = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleCreateUserClick,
  toggleShowChat,
  // showMainDashBoard,
  // showDepartments,
  ShowMainView,
  LogOut,
  setMainView,
  setLocale,
  locale,
}) => {
  const intl = useIntl();
  const _MainDashBoard = "MainDashBoard",
    _GraphsView = "GraphsView",
    _ProfileView = "ProfileView",
    _DepartmentsView = "Departments",
    _AdvancedDataPresentation = "AdvancedDataPresentation",
    _UsersView = "Users";

  return (
    <div className="sidebar">
      <ProSidebar
        image={image ? sidebarBg : false}
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div
            className="center"
            // style={{
            //   padding: "24px",
            //   textTransform: "uppercase",
            //   fontWeight: "bold",
            //   fontSize: 14,
            //   letterSpacing: "1px",
            //   overflow: "hidden",
            //   textOverflow: "ellipsis",
            //   whiteSpace: "nowrap",
            // }}
          >
            {intl.formatMessage({ id: "sidebarTitle" })}

            <DropdownButton
              size="sm"
              variant="link"
              className="righty"
              title={locale}
            >
              <Dropdown.Item onClick={() => setLocale("en")}>en</Dropdown.Item>
              <Dropdown.Item onClick={() => setLocale("es")}>es</Dropdown.Item>
            </DropdownButton>
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle" className="justify-content-center ">
            <MenuItem
              active={ShowMainView === _MainDashBoard}
              icon={<FaTachometerAlt />}
              // suffix={
              //   <span className="badge gray">
              //     {intl.formatMessage({ id: "new" })}
              //   </span>
              // }
              onClick={() => setMainView(_MainDashBoard)}
            >
              {intl.formatMessage({ id: "dashboard" })}
            </MenuItem>
            {/* <SubMenu> */}
            <MenuItem icon={<FaList />} onClick={handleCreateUserClick}>
              {intl.formatMessage({ id: "CreateUser" })}
            </MenuItem>
            <MenuItem onClick={toggleShowChat} icon={<FaVoicemail />}>
              {intl.formatMessage({ id: "toAdmins" })}
            </MenuItem>
            {/* </SubMenu> */}
          </Menu>
          <Menu iconShape="circle" className="  justify-content-center ">
            <MenuItem
              icon={<FaAdjust />}
              onClick={() => setMainView(_GraphsView)}
              active={ShowMainView === _GraphsView}
            >
              {intl.formatMessage({ id: "GraphsPresentation" })}
            </MenuItem>
            <MenuItem
              icon={<FaDigitalTachograph />}
              onClick={() => setMainView(_AdvancedDataPresentation)}
              active={ShowMainView === _AdvancedDataPresentation}
            >
              {intl.formatMessage({ id: "AdvancedGraphsPresentation" })}
            </MenuItem>
          </Menu>
          <Menu iconShape="circle" className="  justify-content-center ">
            <MenuItem
              icon={<FaPersonBooth />}
              onClick={() => setMainView(_DepartmentsView)}
              active={ShowMainView === _DepartmentsView}
            >
              {intl.formatMessage({ id: "Departments" })}
            </MenuItem>

            <MenuItem
              icon={<FaPersonBooth />}
              onClick={() => setMainView(_UsersView)}
              active={ShowMainView === _UsersView}
            >
              {intl.formatMessage({ id: "ManageUser" })}
            </MenuItem>
          </Menu>

          <Menu iconShape="circle">
            {/* <SubMenu
              suffix={<span className="badge yellow">3</span>}
              title={intl.formatMessage({ id: "messages" })}
              icon={<FaVoicemail />}
            >
              <MenuItem>{intl.formatMessage({ id: "public" })} </MenuItem>
              <MenuItem>{intl.formatMessage({ id: "private" })} </MenuItem>
            </SubMenu> */}
            <SubMenu
              title={intl.formatMessage({ id: "Account" })}
              icon={<ImProfile />}
              active={ShowMainView === _ProfileView}
            >
              <MenuItem onClick={() => setMainView(_ProfileView)}>
                {intl.formatMessage({ id: "Profile" })}
              </MenuItem>
              <MenuItem onClick={LogOut}>
                {intl.formatMessage({ id: "LogOut" })}
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: "center" }}>
          {/* <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://google.com/"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <FaGithub />
            <span> {intl.formatMessage({ id: 'footerCopyRights' })}</span>
          </a>
        </div> */}
          <div
            style={
              {
                // padding: '20px 10px',
              }
            }
          >
            <span> {intl.formatMessage({ id: "footerCopyRights" })}</span>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default Aside;
