import React from "react";
import { Card, Container, Row } from "react-bootstrap";
import { useIntl } from "react-intl";

function DashBoardHeader({
  totalUsers,
  TotalMinutes,
  TotalOutGoingCalls,
  TotalIncomingCalls,
  TotalMissedCalls,
  scrollSetForGraph,
}) {
  const intl = useIntl();

  const totalMin = "totalMin";
  const totalInc = "totalInc";
  const totalOut = "totalOut";
  const totalMis = "totalMis";

  return (
    <Container fluid className="bg-primary extra-padding">
      <Container fluid>
        <Row>
          <Card className="col-xl-3 col-md-6">
            <Card.Body>
              <h5 className="card-title text-uppercase text-muted mb-0">
                {intl.formatMessage({ id: "TotalUsers" })}
              </h5>
              <span className="h2 font-weight-bold mb-0">{totalUsers}</span>
            </Card.Body>
          </Card>
          <Card className="col-xl-3 col-md-6">
            <Card.Body>
              <h5
                onClick={() => {
                  scrollSetForGraph(totalMin);
                }}
                className="card-title text-uppercase text-muted mb-0"
              >
                {intl.formatMessage({ id: "TotalMinutes" })}
              </h5>
              <span className="h2 font-weight-bold mb-0">{TotalMinutes}</span>
            </Card.Body>
          </Card>
          <Card className="col-xl-3 col-md-6">
            <Card.Body>
              <h5
                onClick={() => {
                  scrollSetForGraph(totalInc);
                }}
                className="card-title text-uppercase text-muted mb-0"
              >
                {intl.formatMessage({ id: "TotalIncomingCalls" })}
              </h5>
              <span className="h2 font-weight-bold mb-0">
                {TotalIncomingCalls}
              </span>
            </Card.Body>
          </Card>
          <Card className="col-xl-3 col-md-6">
            <Card.Body>
              <h5
                onClick={() => {
                  scrollSetForGraph(totalMis);
                }}
                className="card-title text-uppercase text-muted mb-0"
              >
                {intl.formatMessage({ id: "TotalMissedCalls" })}
              </h5>
              <span className="h2 font-weight-bold mb-0">
                {TotalMissedCalls}
              </span>
            </Card.Body>
          </Card>
          <Card className="col-xl-3 col-md-6">
            <Card.Body>
              <h5
                onClick={() => {
                  scrollSetForGraph(totalOut);
                }}
                className="card-title text-uppercase text-muted mb-0"
              >
                {intl.formatMessage({ id: "TotalOutGoingCalls" })}
              </h5>
              <span className="h2 font-weight-bold mb-0">
                {TotalOutGoingCalls}
              </span>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </Container>
  );
}

export default DashBoardHeader;
