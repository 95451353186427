import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import app from "../base";
import { firestore } from "../base";

import { AuthContext } from "../auth/Auth.js";
// import NavBar from "./components/NavBar/NavBar";
import LoginComponent from "./components/LoginComponents/LoginComponent";
// import LoginComponent from './components/LoginComponents/LoginComp';

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        // .then(history.push("/"));
      } catch (error) {
        alert(error);
      }

      // console.log("ok works e: " +email.value+" p: "+ password.value);
    },
    [history]
  );

  const handleSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      const { username, email, password, licence } = event.target.elements;
      try {
        //await app
        //   .auth()
        //   .createUserWithEmailAndPassword(email.value, password.value);

        await firestore
          .collection("NewUserRequests")
          .doc(licence.value.toString())
          .collection("Requests")
          .add({
            username: username.value.toString(),
            email: email.value.toString(),
            password: password.value.toString(),
            licence: licence.value.toString(),
          })
          .then(
            alert(
              "account requested. Admins will have access to request if request is valid."
            )
          )
          .catch((err) =>
            alert("failed to request account. Try again. " + err)
          );

        history.push("/");
      } catch (error) {
        alert(error);
      }

      // console.log("ok works e: " +email.value+" p: "+ password.value);
    },
    [history]
  );

  const handlePWReset = useCallback(
    async (event) => {
      event.preventDefault();
      const { email } = event.target.elements;
      try {
        await app.auth().sendPasswordResetEmail(email.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }

      // console.log("ok works e: " +email.value+" p: "+ password.value);
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {/* <NavBar /> */}

      {/* <h1>Log in</h1> */}
      {/* <form onSubmit={handleLogin}>
        <label>
          Email
          <input name="email" type="email" placeholder="Email" />
        </label>
        <label>
          Password
          <input name="password" type="password" placeholder="Password" />
        </label>
        <button type="submit">Log in</button>
      </form> */}

      {/* <FormExample/> */}

      <LoginComponent
        handleLogin={handleLogin}
        handleSignUp={handleSignUp}
        handlePWReset={handlePWReset}
      />
    </div>
  );
};

export default withRouter(Login);
