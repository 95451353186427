import React, { Component } from "react";
import { Card, Container } from "react-bootstrap";
// import { auth } from "../services/firebase";
// import { db } from "../services/firebase";
// import { AuthContext } from "../../../auth/Auth.js";
// import app from "../../../base.js";
import { auth, db } from "../../../base.js";

// import * as firebase from 'firebase';

// import db  from "firebase/database";
// import auth from "firebase/auth";

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      chats: [],
      content: "",
      readError: null,
      writeError: null,
      buttonActive: true,
    };
    this.myRef = React.createRef();
    this.oRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async componentDidMount() {
    this.setState({ readError: null });
    try {
      db.ref("chats")
        .limitToLast(20)
        .on("value", (snapshot) => {
          let chats = [];
          snapshot.forEach((snap) => {
            chats.push(snap.val());

            //console.log(JSON.stringify(snap.val()));
          });
          let len = chats.length - 1;
          if (len < 0) {
            len = 0;
          }
          this.setState({ chats, chatLen: len });

          setTimeout(() => {
            try {
              if (this.myRef.current) {
                this.processChatContents();
              }
            } catch (error) {
              console.log(error);
            }
          }, 100);
        });
      // const rootRef = firebase.database().ref().child('react');
      // const speedRef = rootRef.child('speed');

      // speedRef.on('value', snap => {
      //   this.setState({
      //     speed: snap.val()
      //   });
      // });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  handleChange(event) {
    this.setState({
      content: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    console.log(this.state.content);
    if (this.state.content === "" || !this.state.content.trim()) {
      this.setState({ content: "" });
      return;
    }
    this.setState({ writeError: null, buttonActive: false });
    try {
      // console.log(this.state.user.displayName);
      await db.ref("chats").push({
        content: this.state.content,
        timestamp: Date.now(),
        uid: this.state.user.uid,
        name: "MasterAdmin",
      });
      this.setState({ content: "" });
      setTimeout(() => {
        this.setState({ buttonActive: true });
      }, 200);
    } catch (error) {
      this.setState({ writeError: error.message });
    }
  }

  render() {
    return (
      <Container>
        <Card>
          <Card.Header>Message room for Admins and MasterAdmin</Card.Header>
          <Card.Body className="limitHight">
            <div className="chats">
              {this.state.chats.map((chat, i) => {
                return (
                  <Card
                    ref={this.state.chatLen === i ? this.myRef : this.oRef}
                    key={chat.timestamp}
                    className="tiny-margin"
                  >
                    <Card.Body>
                      {chat.name ? (
                        <h6>
                          {chat.name}
                          <small> says</small>
                        </h6>
                      ) : (
                        ""
                      )}

                      {chat.content}
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </Card.Body>
          <Card.Footer>
            <div>
              <form onSubmit={this.handleSubmit}>
                <input
                  onChange={this.handleChange}
                  value={this.state.content}
                ></input>
                {this.state.error ? <p>{this.state.writeError}</p> : null}
                {this.state.buttonActive ? (
                  <button type="submit">Send</button>
                ) : (
                  <button type="button">...</button>
                )}
              </form>
              <div>
                Logged in as: <strong>{this.state.user.email}</strong>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </Container>
    );
  }
}
