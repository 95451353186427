export default {
  ar: {
    title: " ار To be Translated",
    sidebarTitle: "پرو سايد بار",
    description:
      "شريط جانبي مع قوائم منسدلة وعدد غير محدود من القوائم الفرعية المتداخلة",
    dashboard: "لوحة القيادة",
    components: "مكونات",
    dropdown: "اسقاط",
    submenu: "قائمة فرعية",
    multiLevel: "متعدد المستويات",
    collapsed: "انطوى",
    rtl: "ر ت ل",
    image: "صورة الخلفية",
    new: "جديد",
    withPrefix: "مع البادئة",
    withSuffix: "مع اللاحقة",
    viewSource: "عرض الكود",
    footerCopyRights: "MOV365 All Rights Reserved. Tobe Translated",
  },
  en: {
    title: "MOV365 Pro ",
    sidebarTitle: "MOV365 Pro - Admin",
    sidebarTitleMA: "MOV365 Pro - MasterAdmin",
    sidebarTitleUser: "MOV365 Pro - User",
    description:
      "MOV365 Pro supports logging and managing calls on a corporate level",
    dashboard: "Dashboard",
    components: "Components",
    dropdown: "Dropdown",
    submenu: "Submenu",
    multiLevel: "Multi Level",
    collapsed: "Collapsed",
    rtl: "RTL",
    image: "Background image",
    new: "NEW",
    withPrefix: "With Prefix",
    withSuffix: "With Suffix",
    viewSource: "View Source",
    footerCopyRights: "© MOV365 PRO All Rights Reserved.",
    CreateUser: "Create New User",
    CloseUserCreationBar: "Close User Creation form",
    messages: "Messages",
    language: "en",
    private: "Private",
    public: "Public",
    Departments: "Departments",
    LogOut: "Log Out",
    Account: "Account",
    Companies: "Manage Companies",
    CreateCompany: "Create New Company",
    ManageUser: "Manage User",
    GraphsPresentation: "Graphical Presentation",
    Profile: "Profile",
    toAdmins: "Admins Messages",
    AdvancedGraphsPresentation: "Advanced Log Presentation",
    Available: "Available",
    Busy: "Busy",
    OnCall: "OnCall",
    Unknown: "Unknown",
    TotalUsers: "Total Users",
    TotalMinutes: "Total Minutes",
    TotalIncomingCalls: "Total Incoming Calls",
    TotalMissedCalls: "Total Missed Calls",
    TotalOutGoingCalls: "Total OutGoing Calls",
    YourTotalMinutes: "Your Total Minutes",
    YourTotalIncomingCalls: "Your Total Incoming Calls",
    YourTotalOutGoingCalls: "Your Total OutGoing Calls",
    YourTotalMissedCalls: "Your Total Missed Calls",
    Department: "Department",
    Youbelongto: "You belong to",
    Assignto: "Assign to",
    Editcredential: "Edit Credential",
    ChangeCredentials: "Change Credentials",
  },
  fr: {
    Available: "frAvailable",
    Busy: "frBusy",
    OnCall: "frOnCall",
    Unknown: "frUnknown",
  },
  es: {
    title: "MOV365PRO ",
    sidebarTitle: "MOV365PRO - Administrador",
    sidebarTitleMA: "MOV365PRO - MasterAdmin",
    sidebarTitleUser: "MOV365PRO - Usuario",
    description:
      "MOV365PRO es la plataforma más completa para potenciar las comunicaciones de tu empresa",
    dashboard: "Panel",
    components: "Componentes",
    dropdown: "Desplegable",
    submenu: "Submenu",
    multiLevel: "Multi Nivel",
    collapsed: "Colapsado",
    rtl: "RTL",
    image: "Background image",
    new: "NUEVO",
    withPrefix: "With Prefix",
    withSuffix: "With Suffix",
    viewSource: "View Source",
    footerCopyRights: "© MOV365PRO Todos los derechos reservados.",
    CreateUser: "Crear nuevo usuario",
    CloseUserCreationBar: "Cerrar formulario de creación de usuarios",
    messages: "Mensaje",
    language: "es",
    private: "Privado",
    public: "Público",
    Departments: "Departamentos",
    LogOut: "Cerrar sesión",
    Account: "Cuenta",
    Companies: "Administrar empresas",
    CreateCompany: "Crear nueva empresa",
    ManageUser: "Administrar usuario",
    GraphsPresentation: "Gráficos",
    Profile: "Perfil",
    toAdmins: "Mesnajes al administrador",
    AdvancedGraphsPresentation: "Estadísticas",
    Available: "Disponible",
    Busy: "Ocupado",
    OnCall: "EnLlamada",
    Unknown: "Desconocido",
    TotalUsers: "Total de usuarios",
    TotalMinutes: "Minutos totales",
    TotalIncomingCalls: "Total de llamadas entrantes",
    TotalMissedCalls: "Toral de llamadas perdidas",
    TotalOutGoingCalls: "Toral de llamadas salientes",
    YourTotalMinutes: "Tus minutos totales",
    YourTotalIncomingCalls: "Total de llamadas entrantes",
    YourTotalOutGoingCalls: "Total de llamadas salientes",
    YourTotalMissedCalls: "Total de llamadas perdidas",
    Department: "Departamento",
    Youbelongto: "Perteneces a",
    Assignto: "Asignar a",
    Editcredential: "Editar usuario",
    ChangeCredentials: "Editar usuario",
  },
};
