import React, { useState } from "react";

const VerificationPrompt = ({ emailMsg, verify, logOut }) => {
  const [verifyable, setVerifyable] = useState(true);

  function verification(params) {
    setVerifyable(false);
    verify();
  }
  return (
    <div className="d-flex  justify-content-center">
      <div className="d-flex align-items-stretch">
        <div className="card">
          <p> </p>
          <p> </p>
          <p> </p>

          <h3>{emailMsg}</h3>
          <p> </p>
          <p> </p>
          <p> </p>

          <p className="align-self-center">
            {verifyable ? (
              <button className="btn-primary" onClick={() => verification()}>
                Send Verification Email
              </button>
            ) : (
              <button className="btn">Send Verification Email</button>
            )}
          </p>
          <p> </p>
          <p> </p>
          <p> </p>
          <h6 className="align-self-center">
            If already verified, please Log In again.
          </h6>
          <p> </p>
          <p className="align-self-center">
            <button className="btn-primary" onClick={logOut}>
              Log In
            </button>
          </p>
          <p> </p>
        </div>
      </div>
    </div>
  );
};

export default VerificationPrompt;
