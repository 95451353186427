import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
// import { db } from "../../../../base.js";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

// class SelectDepartment extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { value: "All" };
//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   handleChange(event) {
//     this.setState({ value: event.target.value });
//   }

//   async handleSubmit(event) {
//     event.preventDefault();
//     //alert("You selected: " + this.state.value);

//     try {
//       // await db.ref("AdminDashBoardDepartment").update({
//       //   currentDashBoard: this.state.value,
//       //   timestamp: Date.now(),
//       // });
//       console.log("You selected: " + this.state.value);
//       console.log(JSON.stringify(event.value));
//     } catch (error) {
//       alert("error: " + error);
//     }
//   }

//   componentDidMount() {
//     // try {
//     //   db.ref("AdminDashBoardDepartment").on("value", (snapshot) => {
//     //     this.setState({ value: snapshot.val().currentDashBoard });
//     //     console.log(snapshot.val());
//     //   });
//     // } catch (error) {
//     //   console.log(error);
//     //   alert("error occurred. " + error);
//     // }
//   }

//   componentWillUnmount() {
//     // try {
//     //   db.ref("AdminDashBoardDepartment").off();
//     // } catch (error) {
//     //   console.log(error);
//     //   alert("error callback on unmounting. " + error);
//     // }
//   }

//   render() {
//     return (
//       /*
//       <form onSubmit={this.handleSubmit}>
//         <div className="wrapper_radiogroup">
//           <div
//             className="btn-group-vertical"
//             value={this.state.value}
//             onChange={this.handleChange}
//           >
//             <span>
//               <input
//                 className="state"
//                 type="radio"
//                 name="app"
//                 id="a"
//                 value="a"
//               />
//               <label className="label" for="a">
//                 <div className="indicator"></div>
//                 <span className="text">a) close</span>
//               </label>
//             </span>

//             <div className="wrapper">
//               <input
//                 className="state"
//                 type="radio"
//                 name="app"
//                 id="c"
//                 value="c"
//               />
//               <label className="label" for="c">
//                 <div className="indicator"></div>
//                 <span className="text">c) delete</span>
//               </label>
//             </div>

//             <span>
//               <input
//                 type="radio"
//                 id="oddsPref"
//                 name="oddsPref"
//                 value="decimal"
//               />
//               <label>Decimal</label>
//             </span>

//             <input type="submit" value="Submit" />
//           </div>
//         </div>
//       </form>*/

//       <div className="wrapper_radiogroup">
//         <Button variant="primary" onClick={handleShow}>
//           Launch demo modal
//         </Button>

//         <Modal show={show} onHide={handleClose}>
//           <Modal.Header closeButton>
//             <Modal.Title>Modal heading</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={handleClose}>
//               Close
//             </Button>
//             <Button variant="primary" onClick={handleClose}>
//               Save Changes
//             </Button>
//           </Modal.Footer>
//         </Modal>
//   <div className="column">
//     <div
//       className="btn-toggle"
//       onClick={() => this.props.handleToggleSidebar(true)}
//     >
//       <FaBars />
//     </div>
//     <div className="d-flex justify-content-center">
//       <h6>
//         Current department for dashboard: {this.props.dashBoardDepartment}
//       </h6>
//     </div>
//     <form onSubmit={this.handleSubmit}>
//       <div value={this.state.value} onChange={this.handleChange}>
//         <div className="radiogroup">
//           <div className="wrapper">
//             <input
//               className="state"
//               type="radio"
//               name="app"
//               id="a"
//               value="All"
//               checked={this.props.dashBoardDepartment === "All"}
//               onChange={(e) => {}}
//             />
//             <label className="label" htmlFor="a">
//               <div className="indicator"></div>
//               <span className="text">All</span>
//             </label>
//           </div>
//           <div className="wrapper">
//             <input
//               className="state"
//               type="radio"
//               name="app"
//               id="b"
//               value="RRHH"
//               checked={this.props.dashBoardDepartment === "RRHH"}
//               onChange={(e) => {}}
//             />
//             <label className="label" htmlFor="b">
//               <div className="indicator"></div>
//               <span className="text">RRHH</span>
//             </label>
//           </div>
//           <div className="wrapper">
//             <input
//               className="state"
//               type="radio"
//               name="app"
//               id="c"
//               value="Admin"
//               checked={this.props.dashBoardDepartment === "Admin"}
//               onChange={(e) => {}}
//             />
//             <label className="label" htmlFor="c">
//               <div className="indicator"></div>
//               <span className="text">Admin</span>
//             </label>
//           </div>
//           <div className="wrapper">
//             <input
//               className="state"
//               type="radio"
//               name="app"
//               id="d"
//               value="Etc"
//               checked={this.props.dashBoardDepartment === "Etc"}
//               onChange={(e) => {}}
//             />
//             <label className="label" htmlFor="d">
//               <div className="indicator"></div>
//               <span className="text">Etc</span>
//             </label>
//           </div>
//         </div>
//       </div>
//       <p></p>
//       <div className="box-1">
//         <button className="btn-one" type="submit" value="Submit">
//           <span>SAVE</span>
//         </button>
//       </div>
//     </form>
//   </div>
// </div>
//     );
//   }
// }
// export default SelectDepartment;

function SelectDepartment({
  handleToggleSidebar,
  dashBoardDepartment,
  handleSubmit,
  ModifyDepartment,
  Departments,
  departmentModifying,
}) {
  const [show, setShow] = useState(false);
  // const [modifying, setModifying] = useState(false);
  const [deptName, setDeptName] = useState("");

  const handleClose = () => {
    setShow(false);
    // setModifying(false);
  };
  const handleShow = () => setShow(true);
  const [currentSelected, setcurrentSelected] = useState("All");

  function getModal() {
    return (
      <div>
        {/* <div className="box-1">
          <Button className="btn-one" onClick={handleShow}>
            Launch static backdrop modal
          backdrop="static"
          </Button>
        </div> */}

        <div className="box-1">
          <button className="btn-one" type="button" onClick={handleShow}>
            <span>Add / Edit</span>
          </button>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add or Remove Department</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="block" eventKey="0">
                    Add
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form>
                      <Form.Group controlId="formBasicText">
                        <Form.Label>Department name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Department name"
                          onChange={(e) => {
                            setDeptName(e.target.value);
                          }}
                        />
                        <Form.Text className="text-muted">
                          Type Department name to Add.
                        </Form.Text>
                      </Form.Group>
                      {departmentModifying ? (
                        <Button variant="btn" type="button">
                          Adding...
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          type="button"
                          onClick={() => {
                            console.log(deptName);
                            ModifyDepartment(true, deptName);
                          }}
                        >
                          Add
                        </Button>
                      )}
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="block" eventKey="1">
                    Remove
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Form>
                      <Form.Group controlId="formBasicText">
                        <Form.Label>Department name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Department name"
                          onChange={(e) => {
                            setDeptName(e.target.value);
                          }}
                        />
                        <Form.Text className="text-muted">
                          Type Department name exactly to remove.
                        </Form.Text>
                      </Form.Group>
                      {departmentModifying ? (
                        <Button variant="btn" type="button">
                          Removing...
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          type="button"
                          onClick={() => {
                            console.log(deptName);
                            ModifyDepartment(false, deptName);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            {departmentModifying ? (
              ""
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                Done
              </Button>
            )}
            {/*departmentModifying ? (
              <Button variant="primary">Confirming...</Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  // setModifying(true);
                  handleClose();
                  ModifyDepartment(false, "myDepartment");
                }}
              >
                Confirm
              </Button>
            )*/}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  const departmentsToRender = Departments.map((item) => (
    <div className="wrapper" key={item}>
      <input
        className="state"
        type="radio"
        name="app"
        id={item}
        value={item}
        checked={dashBoardDepartment === item}
        onChange={(e) => {
          // setcurrentSelected(e.target.value);
        }}
      />
      <label className="label" htmlFor={item}>
        <div className="indicator"></div>
        <span className="text">{item}</span>
      </label>
    </div>
  ));

  const onRadiochange = (e) => {
    // e.priventDefault();
    // console.log(e.target.value);
    setcurrentSelected(e.target.value);
  };

  //numbers.map((item) => {
  // console.log(item);
  // <li>{item}</li>;

  // <div>
  {
    /* <div className="wrapper">
        <input
          className="state"
          type="radio"
          name="app"
          id="a"
          value={item}
          checked={dashBoardDepartment === { item }}
          onChange={(e) => {}}
        />
        <label className="label" htmlFor="a">
          <div className="indicator"></div>
          <span className="text">{item}</span>
        </label>
      </div> */
  }
  // </div>;
  // });

  return (
    <div className="wrapper_radiogroup">
      <div className="column">
        <div
          className="btn-toggle tiny-margin"
          onClick={() => handleToggleSidebar(true)}
        >
          <FaBars />
        </div>
        <div className="d-flex justify-content-center">
          <h6>Current department for dashboard: {dashBoardDepartment}</h6>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="radiogroup" onChange={onRadiochange}>
              {departmentsToRender}
              {getModal()}

              {/* <ul>{listItems}</ul> */}
              {/* <div className="wrapper">
                <input
                  className="state"
                  type="radio"
                  name="app"
                  id="b"
                  value="RRHH"
                  checked={dashBoardDepartment === "RRHH"}
                  onChange={(e) => {}}
                />
                <label className="label" htmlFor="b">
                  <div className="indicator"></div>
                  <span className="text">RRHH</span>
                </label>
              </div>
              <div className="wrapper">
                <input
                  className="state"
                  type="radio"
                  name="app"
                  id="c"
                  value="Admin"
                  checked={dashBoardDepartment === "Admin"}
                  onChange={(e) => {}}
                />
                <label className="label" htmlFor="c">
                  <div className="indicator"></div>
                  <span className="text">Admin</span>
                </label>
              </div>
              <div className="wrapper">
                <input
                  className="state"
                  type="radio"
                  name="app"
                  id="d"
                  value="Etc"
                  checked={dashBoardDepartment === "Etc"}
                  onChange={(e) => {}}
                />
                <label className="label" htmlFor="d">
                  <div className="indicator"></div>
                  <span className="text">Etc</span>
                </label>
              </div> */}
            </div>
          </div>
          <p></p>
          <div className="box-1">
            <button
              className="btn-one"
              type="button"
              onClick={() => handleSubmit(currentSelected)}
            >
              <span>SAVE</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  // return <div>What we have here? - {dashBoardDepartment}</div>;
}

export default SelectDepartment;
