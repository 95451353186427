import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";

import {
  Card,
  Button,
  Dropdown,
  ButtonGroup,
  Modal,
  Container,
  Form,
} from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import app, { BACKEND_BASE_URL } from "../../../base.js";
import axios from "axios";

import Accordion from "react-bootstrap/Accordion";

function UserManagement({
  locale,
  currentUsersToTrack,
  dashBoardBoardsList,
  allUsers,
  assignUserToADepartment,
  dashBoardDepartment,
  handleToggleSidebar,
}) {
  const intl = useIntl();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modifyMessage, setModifyMessage] = useState("");
  const [showModifying, setShowModifying] = useState(false);
  const userNow = useRef("");
  const departmentNow = useRef("");
  const uidNow = useRef("");
  const [UpdateState, setUpdateState] = useState("");
  const [show, setShow] = useState(false);
  const [emailValid, setemailValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const currentEmail = useRef("");
  const oldEmail = useRef("");
  const name = useRef("");
  const number = useRef("");

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function handleClose1(params) {
    currentEmail.current = "";
    departmentNow.current = "";
    setUpdateState("");
    oldEmail.current = "";
    setShow(false);
    setemailValid(false);
    setNameValid(false);
    setNumberValid(false);
    name.current = "";
    number.current = "";
  }

  function handleClose() {
    setModifyMessage("");

    setShowConfirmation(false);
  }

  function handleShow() {
    setShowConfirmation(true);
  }

  async function emailUpdater(newEmail_) {
    setUpdateState("Updating");

    try {
      //setcreatingUser(true);
      console.log(newEmail_);

      await app
        .auth()
        .currentUser.getIdTokenResult()
        .then((idToken) => {
          let url = BACKEND_BASE_URL + "update_user/";

          // console.log(url);

          //uid, licence, email, newEmail
          //let root = idToken.claims.licence;
          //let uid = idToken.claims.user_id;
          console.log("Updating a user");
          const body = {
            uid: uidNow.current,
            newEmail: newEmail_,
            licence: idToken.claims.Licence,
            display_name: name.current,
            phone_number: number.current,
          };
          // console.log("body", body);

          axios
            .post(url, body, {
              headers: {
                Authorization: "Bearer " + idToken.token,
                // 'Content-Type' : 'text/plain'
              },
            })
            .then(function (response) {
              //let obj = response;
              // console.log(response.data);
              // console.log(response.data.message);
              //setcreatedUser(true);
              //setUserCreationResult(JSON.stringify(response.data.message));
              //getAllUsers();
              if (response.data.message === "Successfully updated user.") {
                //removeUser();
                setUpdateState("Updated");
              } else {
                throw new Error("HTTP status " + response.status);
              }
            })
            .catch(function (error) {
              console.log(error);
              setUpdateState("UpdateFailed");

              //setUserCreationResult(JSON.stringify(error.message));
              //setcreatedUser(true);
            })
            .then(function () {
              // always executed
              //setcreatingUser(false);
            });
        });
    } catch (error) {
      alert(error);
    }
  }

  async function removeUser() {
    setShowModifying(true);
    const message = await assignUserToADepartment(
      false,
      uidNow.current.toString(),
      departmentNow.current.toString()
    );
    // console.log("now is ::: ", message);
    if (message === "Success") {
      handleClose();

      setShowModifying(false);
    } else {
      setShowModifying(false);
      setModifyMessage("Failed to remove. Try again later.");
    }
  }

  function getElementsToProcess() {
    try {
      if (allUsers.length === 0) {
        //currentUsersToTrack.length
        // array does not exist, is not an array, or is empty
        // ⇒ do not attempt to process array
        return (
          <div>
            <p>
              No users in this department: "{dashBoardDepartment}" yet. Please
              try adding User from the dashBoard panel. Or please try assigning
              user by selecting department 'All'.
            </p>
          </div>
        );
      }

      // function showConfirmationDialogue(user, department) {}
      // function prepareDashBoardForUserManagementView() {
      //   dashBoardBoardsList = dashBoardBoardsList.filter(function (value) {
      //     return value !== "All";
      //   });
      // }

      // const users = currentUsersToTrack.map((user) =>
      //   allUsers.find((obj) => obj.id == user)
      // );

      const listItems = allUsers.map((user) => (
        <Card key={user.email}>
          <Card.Body>
            <li key={user.email}>
              <p></p>
              <div>displayName: {user.displayName}</div>
              <div>email: {user.email}</div>
              {/* <div>uid: {user.uid}</div> */}
              {/* <div>IsOnline: {user.IsOnline.toString()}</div>
              <div>totalIncomingCalls: {user.totalIncomingCalls}</div>
              <div>totalMinute: {user.totalMinute}</div>
              <div>totalMissedCalls: {user.totalMissedCalls}</div>
              <div>totalOutGoingCalls: {user.totalOutGoingCalls}</div> */}
              <p></p>
            </li>
            {dashBoardDepartment !== "All" ? (
              <Button
                variant="outline-danger"
                // style={{ height: "100%", width: "100%" }}
                onClick={
                  () => {
                    userNow.current = user.displayName;
                    departmentNow.current = dashBoardDepartment;
                    uidNow.current = user.uid;
                    handleShow();
                  }
                  //showConfirmationDialogue(user.uid, dashBoardDepartment)
                }
              >
                Remove '{user.displayName}' from '{dashBoardDepartment}'
              </Button>
            ) : (
              <div>
                <Dropdown as={ButtonGroup}>
                  <Button variant="secondary">
                    {/* Assign To */}
                    {intl.formatMessage({ id: "Assignto" })}
                  </Button>

                  <Dropdown.Toggle
                    split
                    variant="success"
                    id="dropdown-split-basic"
                  />

                  <Dropdown.Menu>
                    {dashBoardBoardsList.map((group, index) =>
                      group === "All" ? (
                        ""
                      ) : (
                        <Dropdown.Item
                          key={index.toString()}
                          onClick={() => {
                            assignUserToADepartment(
                              true,
                              user.uid.toString(),
                              group.toString()
                            );
                          }}
                        >
                          {group}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {/* TODO add space */}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="secondary"
                  onClick={() => {
                    departmentNow.current = dashBoardDepartment;
                    oldEmail.current = user.email;
                    uidNow.current = user.uid;
                    setShow(true);
                  }}
                >
                  {/* Edit credential */}
                  {intl.formatMessage({ id: "Editcredential" })}
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
      ));
      return (
        <div>
          <p>
            Showing users for department '{dashBoardDepartment}':{" "}
            {dashBoardBoardsList.length === 1
              ? "No sub-department to assign to. Create one from 'Departments' tab."
              : ""}
          </p>

          <ol>{listItems}</ol>
          <Modal show={showConfirmation} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                You want to remove "{userNow.current}" from department "
                {departmentNow.current}"?
              </p>
              <p>{modifyMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              {showModifying ? (
                <Button>Removing...</Button>
              ) : (
                <Button variant="danger" onClick={removeUser}>
                  Confirm
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      );
    } catch (error) {
      console.log("error watch in here.... ", error);
      return <div>Something went wrong. Try again.</div>;
    }
  }

  function getUpdateForm() {
    switch (UpdateState) {
      case "Updating":
        return (
          <Modal show={show} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Edit profile Image or Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>Updating Password...</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary">Updating...</Button>
            </Modal.Footer>
          </Modal>
        );

      case "Updated":
        return (
          <Modal show={show} onHide={handleClose1} keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>
                {intl.formatMessage({ id: "Editcredential" })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>Credential has been updated successfully.</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                onClick={() => {
                  handleClose1();
                }}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case "UpdateFailed":
        return (
          <Modal
            show={show}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {intl.formatMessage({ id: "Editcredential" })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>Failed to update credential.</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="warning"
                onClick={() => {
                  handleClose1();
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        );

      default:
        return (
          <Modal show={show} onHide={handleClose1} keyboard={false}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Edit Credential</Modal.Title> */}
              <Modal.Title>
                {intl.formatMessage({ id: "Editcredential" })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="block" eventKey="0">
                      {/* Change Credentials */}
                      {intl.formatMessage({ id: "ChangeCredentials" })}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Form>
                        <Form.Group>
                          <Form.Control
                            type="email"
                            placeholder="Type New Email"
                            onChange={(e) => {
                              if (validateEmail(e.target.value)) {
                                // }
                                // if (
                                //   e.target.value.length > 5 &&
                                //   e.target.value.length < 49
                                // )
                                setemailValid(true);
                              } else {
                                setemailValid(false);
                              }
                              currentEmail.current = e.target.value;
                            }}
                          />
                          &nbsp;&nbsp;
                          <Form.Control
                            type="name"
                            placeholder="Type name"
                            onChange={(e) => {
                              if (
                                e.target.value.length > 5 &&
                                e.target.value.length < 49
                              ) {
                                setNameValid(true);
                              } else {
                                setNameValid(false);
                              }
                              name.current = e.target.value;
                            }}
                          />
                          &nbsp;&nbsp;
                          <Form.Control
                            type="number"
                            placeholder="Type Phone Number"
                            onChange={(e) => {
                              if (
                                e.target.value.length > 5 &&
                                e.target.value.length < 20
                              ) {
                                setNumberValid(true);
                              } else {
                                setNumberValid(false);
                              }
                              number.current = e.target.value;
                            }}
                          />
                          &nbsp;&nbsp;
                          <Form.Text className="text-muted">
                            {emailValid ? (
                              ""
                            ) : (
                              <p className="border border-warning">
                                Email is not valid.
                              </p>
                            )}
                          </Form.Text>
                          <Form.Text className="text-muted">
                            {nameValid ? (
                              ""
                            ) : (
                              <p className="border border-warning">
                                Name is not valid.
                              </p>
                            )}
                          </Form.Text>
                          <Form.Text className="text-muted">
                            {numberValid ? (
                              ""
                            ) : (
                              <p className="border border-warning">
                                Number is not valid.
                              </p>
                            )}
                          </Form.Text>
                        </Form.Group>
                      </Form>
                      &nbsp;&nbsp;
                      {emailValid && nameValid && numberValid ? (
                        <Button
                          variant="primary"
                          onClick={() => {
                            emailUpdater(currentEmail.current);
                          }}
                        >
                          Update email
                        </Button>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        );
    }
  }

  return (
    <Container fluid>
      <div
        className="btn-toggle tiny-margin "
        onClick={() => handleToggleSidebar(true)}
      >
        <FaBars />
      </div>
      <p>User Management...</p>
      {getElementsToProcess()}
      {getUpdateForm()}
    </Container>
  );
}

export default UserManagement;
