import React, { useState, useEffect, useRef } from "react";
import AsideUser from "../../../SideBar/AsideUser";
import MainUser from "../../../SideBar/MainUser";
import app, { firestore, FieldValue } from "../../../../../base";
//
import Profile from "../../../Profile/ProfileUser";
// import UserGraphs from "../../../ChartManager/UserGraphs";
import { Card } from "react-bootstrap";
import { format, getUnixTime } from "date-fns";

import { useIntl } from "react-intl";

function UserDashBoard({ locale, setLocale }) {
  const intl = useIntl();

  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [ShowMainView, setShowMainView] = useState("MainDashBoard");
  //const [currentUsersToTrack, setCurrentUsersToTrack] = useState([]);
  const [usersToShow, setusersToShow] = useState([{}]);

  const currentListeners = useRef([]).current;
  const users = useRef([]).current;
  const [CurrentUser, setCurrentUser] = useState({}); //userStats
  const CurrentDashBoard = useRef("");
  const OwnSelf = useRef({});
  const stuff = useRef({});
  // const [thisUser, setThisUser] = useState({});

  ////////////////
  // const arrayPresence = useRef({});
  // const [Presence, setPresence] = useState({});

  /////////////////////////////////////////////////
  {
    async function pushSomeDataToFB() {
      let CurrentParentRef = firestore
        .collection("Companies")
        .doc(stuff.current.root)
        .collection("Users")
        .doc(stuff.current.uid)
        .collection("Stats")
        .doc("Stats");

      //CurrentParentRef - array add value
      console.log(
        "stuff.current.root : ",
        stuff.current.root,
        " , CurrentUser.uid ",
        CurrentUser.current.uid
      );

      console.log("You dude : ", CurrentParentRef.path);
      // return
      // let bigData = { Time: Date.now(), Num: 1234, Dur: 10 };
      // await CurrentParentRef.update({
      //   totalIncomingCalls: FieldValue.arrayUnion(bigData),
      // });

      // var citiesRef = CurrentParentRef.collection("cities");

      // citiesRef.doc("SF").set({
      //   name: "San Francisco",
      //   state: "CA",
      //   country: "USA",
      //   capital: false,
      //   population: 860000,
      //   regions: ["west_coast", "norcal"],
      // });
      // citiesRef.doc("LA").set({
      //   name: "Los Angeles",
      //   state: "CA",
      //   country: "USA",
      //   capital: false,
      //   population: 3900000,
      //   regions: ["west_coast", "socal"],
      // });
      // citiesRef.doc("DC").set({
      //   name: "Washington, D.C.",
      //   state: null,
      //   country: "USA",
      //   capital: true,
      //   population: 680000,
      //   regions: ["east_coast"],
      // });
      // citiesRef.doc("TOK").set({
      //   name: "Tokyo",
      //   state: null,
      //   country: "Japan",
      //   capital: true,
      //   population: 9000000,
      //   regions: ["kanto", "honshu"],
      // });
      // citiesRef.doc("BJ").set({
      //   name: "Beijing",
      //   state: null,
      //   country: "China",
      //   capital: true,
      //   population: 21500000,
      //   regions: ["jingjinji", "hebei"],
      // });

      var totalIncomingCallsRef =
        CurrentParentRef.collection("totalIncomingCalls");
      const result = format(new Date(), "MMddyyyy"); //new Date().toLocaleDateString("en-US"); //getUnixTime(new Date());

      totalIncomingCallsRef
        .doc(result.toString())
        .update({
          1234567: FieldValue.arrayUnion({
            Time: getUnixTime(new Date()),
            Duration: "1h",
          }),
        })
        .then((res) => {
          console.log("res - " + res + " , " + result);
        });
    }
    let listenerx;
    async function retrieveSomeData() {
      let CurrentParentRef = firestore
        .collection("Companies")
        .doc(stuff.current.root)
        .collection("Users")
        .doc(stuff.current.uid)
        .collection("Stats")
        .doc("Stats");

      //CurrentParentRef - array add value
      console.log(
        "stuff.current.root : ",
        stuff.current.root,
        " , CurrentUser.uid ",
        CurrentUser.current.uid
      );
      console.log("You dude : ", CurrentParentRef.path);

      // listenerx = CurrentParentRef.where("totalIncomingCalls", "==", true) //.collection("cities")
      //   .onSnapshot((querySnapshot) => {
      //     var datas = [];
      //     querySnapshot.forEach((doc) => {
      //       datas.push(doc.data().Num);
      //     });
      //     console.log("Retrieved docs are : ", datas.join(", "));
      //   });

      // CurrentParentRef.collection("totalIncomingCalls")
      //   .where("duration", "==", "1h")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       // doc.data() is never undefined for query doc snapshots
      //       console.log(doc.id, " => ", doc.data());
      //     });
      //   })
      //   .catch((error) => {
      //     console.log("Error getting documents: ", error);
      //   });

      // const result = getUnixTime(new Date());

      // console.log("see - " + result + " see - " + new Date());

      const result = format(new Date(), "MMddyyyy");

      var totalIncomingCallsRef = CurrentParentRef.collection(
        "totalIncomingCalls"
      ).doc(result.toString());

      totalIncomingCallsRef.get().then((val) => {
        console.log(JSON.stringify(val.data()));
      });

      // var t = new Date().toISOString();
      // CurrentParentRef.collection("cities")
      //   .where("createdDummyTime", ">", new Date().toISOString())
      //   .onSnapshot((querySnapshot) => {
      //     var cities = [];
      //     querySnapshot.forEach((doc) => {
      //       cities.push(doc.data().name);
      //     });
      //     console.log("Current cities in CA: ", cities.join(", "));
      //   });
    }
  }
  /////////////////////////////////////////////////
  const dettachListeners = () =>
    currentListeners.forEach((listener) => listener());

  //this func runs only at the startup or dept change.
  async function spawnListeners(root, list) {
    //console.log("spawing parameter listeners with : ", root);
    if (currentListeners.length !== 0) {
      await removeListeners();
      users.length = 0;
    }

    let CurrentParentRef = firestore
      .collection("Companies")
      .doc(root)
      .collection("Users");

    for (let index = 0; index < list.length; index++) {
      //console.log("getting: ", list[index]);

      //We need to get the doc on main node of users on same dept because we need to see their pic and name. Strurcture data so that no else comes with it.
      await CurrentParentRef.doc(list[index])
        .get()
        .then((doc) => {
          if (stuff.current.uid === doc.data().uid) {
            //console.log("Its this guy : ", JSON.stringify(doc.data()));
            OwnSelf.current = doc.data();
            setCurrentUser(doc.data());
          }
          let index1 = users.findIndex((x) => x.uid === doc.id); // users.find((obj) => obj.id ==  doc.id)
          if (index1 === -1) {
            //console.log("index1 -1 , could be: ", doc.id);

            users.push(doc.data());
          } else {
            //console.log("element has been found at index: ", index1);
            users[index1] = doc.data();
          }
        });

      let listener = CurrentParentRef.doc(list[index])
        .collection("presenceCondition")
        .doc("presenceCondition")
        .onSnapshot((doc) => {
          //setusersUpdating(true);

          const cUser = list[index];

          //console.log("some data is changed in ");

          if (doc) {
            // console.log(doc.data());

            // console.log("full data is : ", JSON.stringify(doc));

            // console.log("check this out - ", doc.data().uid);

            let index1 = users.findIndex((x) => x.uid === cUser); // users.find((obj) => obj.id ==  doc.id)
            if (index1 === -1) {
              //console.log("index1 -1 , could be: ", cUser);

              users.push(doc.data());
            } else {
              //console.log("element has been found at index: ", index1);
              users[index1].presenceCondition = doc.data().presenceCondition;

              // console.log(
              //   "Just see in here: ",
              //   cUser,
              //   " , ",
              //   JSON.stringify(users[index1]),
              //   " , ",
              //   users[index1].presenceCondition.presenceCondition
              // );
            }
            // console.log("Currently users are: ", JSON.stringify(users));
            setusersToShow([...users]);
          }

          //let id = allUsers.find((obj) => obj.id == doc.id);
          //allUsers[id] = { id: doc.id, ...doc.data() };
          //setShowMainView(ShowMainView);

          //Creates an object map of id to object in arr1

          //Updates the object with corresponding id in arr1Map from arr2,
          //creates a new object if none exists (upsert)

          //users.push(doc.data());
          // console.log("seeeee: ", JSON.stringify(doc.data().presenceCondition));

          //console.log(" usersToShow: ", JSON.stringify(usersToShow));
          //console.log(JSON.stringify(users === usersToShow));

          //console.log(" _users: ", JSON.stringify(users));
        });
      currentListeners.push(listener);
      // console.log(" ___ listeners[index] : ", listeners[index]);
    }
    //////changed here............
    // setusersToShow([...users]);
    //console.log("Currently users are: ", JSON.stringify(users));

    //get current user listener to update his stats.
    var CUserListener = CurrentParentRef.doc(OwnSelf.current.uid)
      .collection("Stats")
      .doc("Stats")
      .onSnapshot((doc) => {
        if (doc) {
          //let index1 = users.findIndex((x) => x.uid === cUser);
          //console.log("Current user : ", JSON.stringify(doc.data()));

          setCurrentUser(doc.data());
        }
      });
    currentListeners.push(CUserListener);

    // console.log("users len: ", users.length, " , users: ", users);
  }

  async function PrepareStats() {
    //get current user listener to update his stats.

    let CurrentParentRef = firestore
      .collection("Companies")
      .doc(stuff.current.root)
      .collection("Users");

    await CurrentParentRef.doc(stuff.current.uid).onSnapshot((doc) => {
      if (stuff.current.uid === doc.data().uid) {
        console.log("Its this guy : ", JSON.stringify(doc.data()));
        OwnSelf.current = doc.data();
        // setThisUser(doc.data());
      } else {
        //never used this block logically.
      }
    });

    var CUserListener = CurrentParentRef.doc(stuff.current.uid)
      .collection("Stats")
      .doc("Stats")
      .onSnapshot((doc) => {
        if (doc) {
          //let index1 = users.findIndex((x) => x.uid === cUser);
          //console.log("Current user : ", JSON.stringify(doc.data()));

          setCurrentUser(doc.data());
        }
      });
    currentListeners.push(CUserListener);
  }

  async function removeListeners() {
    dettachListeners();
  }

  useEffect(() => {
    let departmentListener, dashBoardListener;
    let root = "";

    async function dashBoardListenerFunc(p) {
      let path = firestore
        .collection("Companies")
        .doc(root)
        .collection("departments")
        .doc(p);
      dashBoardListener = await path.onSnapshot((doc) => {
        //console.log("on dashBoardListener");

        let data = doc.data();
        if (data === undefined) {
          // setCurrentUsersToTrack([]);
          users.length = 0;
          //console.log("data === undefined");
          return;
        }
        let depts = [];
        data.users.map((d) => {
          depts.push(d);
        });
        //since we are not showing graphs now we dont need following to update now.
        // setCurrentUsersToTrack(depts);
        //console.log("depts", depts);

        // console.log("populated users : ", users.current);

        // setcurrentDashBoardBoard(depts);
        spawnListeners(root, depts);
        // console.log("currentDashBoard - " + JSON.stringify(depts));
      });
    }
    try {
      app
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          let uid = idTokenResult.claims.user_id;
          root = idTokenResult.claims.licence;

          //OwnSelf.current = uid;
          stuff.current = { uid, root };
          //console.log("idTokenResult ", JSON.stringify(idTokenResult));

          let path = firestore
            .collection("Companies")
            .doc(root)
            .collection("Users")
            .doc(uid)
            .collection("department")
            .doc("department");
          departmentListener = path.onSnapshot((doc) => {
            //CurrentUser.current = doc.data();
            //let currentDashBoard = CurrentUser.current.department;
            //console.log("CurrentUser - " + JSON.stringify(CurrentUser.current));

            let currentDashBoard = doc.data().department;
            //console.log("on currentDashBoard ", doc.data());

            if (
              currentDashBoard !== undefined &&
              currentDashBoard !== "All" &&
              currentDashBoard !== ""
            ) {
              users.length = 0;
              //console.log("Creating function...");
              if (dashBoardListener) {
                dashBoardListener();
              }
              dashBoardListenerFunc(currentDashBoard);
              CurrentDashBoard.current = currentDashBoard;
            } else {
              //console.log("Create your stats for user function...");
              PrepareStats();
            }
          });
        })

        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);

      alert("error occurred. " + error);
    }
    return () => {
      removeListeners();
      departmentListener();
      if (dashBoardListener !== undefined) {
        dashBoardListener();
      }
    };
  }, []);

  function signOut() {
    app.auth().signOut();
  }

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    setLocale(checked ? "ar" : "en");
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  //
  const [isOpen, toggle] = useState(false);

  const handleModal = () => {
    toggle(!isOpen);
  };

  const showMainView = () => {
    setShowMainView("MainDashBoard");
  };

  const showMainDashBoard = () => {
    setShowMainView("MainDashBoard");
  };

  function showGraphs() {
    setShowMainView("ShowGraphs");
  }

  const showProfile = () => {
    setShowMainView("ShowProfile");
  };

  const renderMainView = (param) => {
    switch (param) {
      case "MainDashBoard":
        return (
          <MainUser
            image={image}
            toggled={toggled}
            collapsed={collapsed}
            rtl={rtl}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            handleRtlChange={handleRtlChange}
            handleImageChange={handleImageChange}
            handleModal={handleModal}
            isOpen={isOpen}
            showMainView={showMainView}
            users={users}
            CurrentUser={CurrentUser}
            CurrentDashBoard={CurrentDashBoard.current}
            usersToShow={usersToShow}
          />
        );
      // case "ShowGraphs":
      //   return (
      //     <UserGraphs
      //       rtl={rtl}
      //       toggled={toggled}
      //       handleToggleSidebar={handleToggleSidebar}
      //       usersToShow={usersToShow}
      //     />
      //   );
      case "ShowProfile":
        return (
          <Profile
            rtl={rtl}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            OwnSelf={OwnSelf.current}
            CurrentUser={CurrentUser}
          />
        );
      default:
        return <Card>Error.</Card>;
    }
  };

  return (
    <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
      <AsideUser
        image={image}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCreateUserClick={handleModal}
        showMainDashBoard={showMainDashBoard}
        showProfile={showProfile}
        showGraphs={showGraphs}
        LogOut={signOut}
        setLocale={setLocale}
        locale={locale}
      />
      {/* <Button onClick={pushSomeDataToFB}>pushen</Button>
      <Button onClick={retrieveSomeData}>getit</Button>
      <Button
        onClick={() => {
          listenerx();
        }}
      >
        removeL
      </Button> */}

      {renderMainView(ShowMainView)}
    </div>
  );
}

export default UserDashBoard;
