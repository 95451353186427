import React, { Component } from "react";
import { FormErrors } from "./UserModification/FormErrors";
import Fade from "react-reveal/Fade";
import { Card } from "react-bootstrap";

class CreateNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      email: "",
      phone: "",

      password: "",
      formErrors: { userName: "", email: "", phone: "", password: "" },
      userNameValid: false,
      emailValid: false,
      phoneValid: false,
      passwordValid: false,
      formValid: false,
    };
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let userNameValid = this.state.userNameValid;
    let emailValid = this.state.emailValid;
    let phoneValid = this.state.phoneValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;
      case "phone":
        phoneValid = value.match();
        fieldValidationErrors.phone = phoneValid ? "" : " is invalid";
        break;
      case "password":
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? "" : " is too short";
        break;
      case "userName":
        userNameValid = value.match(
          /^[a-zA-Z0-9]([._](?![._])|[a-zA-Z0-9]){2,18}[a-zA-Z0-9]$/i
        );
        fieldValidationErrors.userName = userNameValid
          ? ""
          : " has invalid character or is too short";
        break;
      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        userNameValid: userNameValid,
        emailValid: emailValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.userNameValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  ShowUI() {
    if (!this.props.createdUser) {
      return (
        <div className="topDiv">
          <form className="UserForm" onSubmit={this.props.onSubmit}>
            <h2></h2>
            <div className="panel panel-default insideDiv">
              <div
                className="invalid-feedback"
                //Boostrap 4 uses some CSS tricks to simplify
                //error handling but we're doing it differently
                //so the next line disables these tricks
                style={{ display: "block" }}
              >
                <Fade bottom when={!this.state.formValid}>
                  <FormErrors formErrors={this.state.formErrors} />
                </Fade>
              </div>
            </div>

            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.userName
              )}`}
            >
              <label htmlFor="userName">User Name</label>
              <input
                type="text"
                required
                className="form-control"
                name="userName"
                placeholder="User Name"
                value={this.state.userName}
                onChange={this.handleUserInput}
              />
            </div>

            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.email
              )}`}
            >
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                required
                className="form-control"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleUserInput}
              />
            </div>
            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.phone
              )}`}
            >
              <label htmlFor="phone">Phone</label>
              <input
                type="phone"
                required
                className="form-control"
                name="phone"
                placeholder="phone"
                value={this.state.phone}
                onChange={this.handleUserInput}
              />
            </div>
            <div
              className={`form-group ${this.errorClass(
                this.state.formErrors.password
              )}`}
            >
              <label htmlFor="password">Give User A Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleUserInput}
              />
            </div>
            <div className="form-group">
              <label htmlFor="role">Role</label>
              <select id="role" className="form-control">
                <optgroup label="User" className="greenSelect">
                  <option value="user">User</option>
                </optgroup>
                {/* <optgroup label="Manager" className="orangeSelect">
              <option value="manager">Manager</option>
            </optgroup> */}
                <optgroup label="Admin" className="redSelect">
                  <option value="Admin" disabled={true}>
                    Admin
                  </option>
                </optgroup>
              </select>
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={!this.state.formValid}
            >
              {this.props.creatingUser ? "Creating ..." : "Create User"}
            </button>
          </form>
        </div>
      );
    } else
      return (
        <Card>
          <Card.Body>
            <h3>Result</h3>
            <div>{this.props.UserCreationResult}</div>
            <button
              type="button"
              onClick={() => {
                this.props.resetState();
                this.props.toggle(false);
              }}
            >
              OK
            </button>
          </Card.Body>
        </Card>
      );
  }

  render() {
    return this.ShowUI();
  }
}

export default CreateNewUser;
