import React from "react";
import { Container, Row } from "react-bootstrap";
import PresenceCard from "./PresenceCard";
import { Card } from "react-bootstrap";

//for admins.......
function UserPresence({ users }) {
  function getElementsToProcess() {
    if (users.length === 0) {
      // array does not exist, is not an array, or is empty
      // ⇒ do not attempt to process array
      return (
        <div>
          <Card>
            Not Assigned to any department. Please wait or contact with admin.
          </Card>
        </div>
      );
    }

    // const listItems = users.map((user) => (
    //   <div xs="4" key={user.displayName}>
    //     <PresenceCard
    //       imageURL={user.photoURL}
    //       presenceCondition={user.presenceCondition}
    //       name={user.displayName}
    //     />
    //   </div>
    // ));
    // return <div>{listItems}</div>;
    try {
      const GridLayout = (
        // <Row
        // className="container"
        // style={{
        //   display: "grid",
        //   gridTemplateColumns: "repeat(3, 1fr)",
        //   gridGap: "10px",
        //   gridAutoRows: "minMax(100px, auto)",
        // }}
        // >
        <Row className="justify-content-md-center">
          {users.map((user) => (
            <Row key={user.displayName}>
              <PresenceCard
                imageURL={user.photoURL}
                presenceCondition={user.presenceCondition}
                name={user.displayName}
              />
            </Row>
          ))}
        </Row>
      );
      return <Container fluid>{GridLayout}</Container>;
    } catch (error) {
      console.log(error);

      return (
        <div>
          <p>Error.</p>
        </div>
      );
    }
  }

  return (
    <Card>
      <Card.Body>
        <Container>{getElementsToProcess()}</Container>
      </Card.Body>
    </Card>
  );
}

export default UserPresence;
