import React from "react";
// import { Container, Row, Col, Image } from "react-bootstrap";
import PresenceCard from "./PresenceCard";
import { Card, Container, Row } from "react-bootstrap";
// import { FaGreaterThanEqual } from "react-icons/fa";

//for users...............
function UserPresence({ users, Presence }) {
  // function getUserCondition(user) {
  //   const id = user.uid;

  //   return id + " , " + PresenceConditions[id].presenceCondition;
  // }
  function getElementsToProcess() {
    if (users === undefined) {
      console.log("Users undefined.");
      return;
    }

    // console.log("Received: ", JSON.stringify(Presence));

    if (users.length === 0) {
      // array does not exist, is not an array, or is empty
      // ⇒ do not attempt to process array
      return (
        <div>
          <p>No User Found Yet.</p>
        </div>
      );
    }
    try {
      const GridLayout = (
        // <Row
        // className="container"
        // style={{
        //   display: "grid",
        //   gridTemplateColumns: "repeat(3, 1fr)",
        //   gridGap: "10px",
        //   gridAutoRows: "minMax(100px, auto)",
        // }}
        // >
        <Row className="justify-content-md-center">
          {users.map((user) => (
            <Row key={user.displayName}>
              <PresenceCard
                imageURL={user.photoURL}
                presenceCondition={Presence[user.uid]}
                name={user.displayName}
              />
            </Row>
          ))}
        </Row>
      );
      // const listItems = users.map((user) => (
      //   <div xs="4" key={user.displayName}>
      //     <PresenceCard
      //       imageURL={user.photoURL}
      //       presenceCondition={Presence[user.uid]}
      //       name={user.displayName}
      //     />
      //   </div>
      // ));
      return <Container fluid>{GridLayout}</Container>;
    } catch (error) {
      console.log(error);

      return (
        <div>
          <p>Error.</p>
        </div>
      );
    }
  }

  return (
    // <Container fluid>
    <Card>
      <Card.Body>{getElementsToProcess()}</Card.Body>
    </Card>
  );
}

export default UserPresence;
