import React from "react";
import { useIntl } from "react-intl";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaTachometerAlt,
  FaGem,
  FaList,
  FaHeart,
  FaPersonBooth,
  FaVoicemail,
} from "react-icons/fa";
import sidebarBg from "./assets/Mov365ProSideLogo.png";

const AsideMasterAdmin = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleCreateUserClick,
  LogOut,
  setShowMainView,
  toggleShowChat,
}) => {
  const intl = useIntl();
  const _MainDashBoard = "MainDashBoard",
    _Companies = "Companies";

  return (
    <div className="sidebar">
      <ProSidebar
        image={image ? sidebarBg : false}
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div
            style={{
              padding: "24px",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 14,
              letterSpacing: "1px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {intl.formatMessage({ id: "sidebarTitleMA" })}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem
              icon={<FaTachometerAlt />}
              suffix={
                <span className="badge gray">
                  {intl.formatMessage({ id: "new" })}
                </span>
              }
              onClick={() => setShowMainView(_MainDashBoard)}
            >
              {intl.formatMessage({ id: "dashboard" })}
            </MenuItem>
            <MenuItem
              icon={<FaList />}
              onClick={() => {
                handleCreateUserClick();
              }}
            >
              {""}
              {intl.formatMessage({ id: "CreateCompany" })}
            </MenuItem>

            <MenuItem
              icon={<FaPersonBooth />}
              onClick={() => setShowMainView(_Companies)}
            >
              {intl.formatMessage({ id: "Companies" })}
            </MenuItem>
          </Menu>
          <Menu iconShape="circle">
            <MenuItem onClick={toggleShowChat} icon={<FaVoicemail />}>
              {intl.formatMessage({ id: "toAdmins" })}
            </MenuItem>
            {/* <SubMenu
              // suffix={<span className="badge yellow">3</span>}
              title={intl.formatMessage({ id: "messages" })} 
              icon={<FaVoicemail />}
            >
              { <MenuItem onClick={toggleShowChat}>
                {intl.formatMessage({ id: "toAdmins" })}
              </MenuItem> }
            </SubMenu> */}
            <SubMenu
              title={intl.formatMessage({ id: "Account" })}
              icon={<FaHeart />}
            >
              <MenuItem onClick={LogOut}>
                {intl.formatMessage({ id: "LogOut" })}
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: "center" }}>
          {/* <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://google.com/"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <FaGithub />
            <span> {intl.formatMessage({ id: 'footerCopyRights' })}</span>
          </a>
        </div> */}
          <div
            style={
              {
                // padding: '20px 10px',
              }
            }
          >
            <FaGem />
            <span> {intl.formatMessage({ id: "footerCopyRights" })}</span>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default AsideMasterAdmin;
