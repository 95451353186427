import React, { useState } from "react";
import { useIntl } from "react-intl";
import { FaBars } from "react-icons/fa";
//
import DashBoardHeader from "../DashBoard/DashBoardHeader/DashBoardHeaderMain";
// import Chart from "../ChartManager/Chart";
import UserPresence from "../Presence/UserPresenceAdmin";
import styled from "styled-components";
import app, { BACKEND_BASE_URL } from "../../../base.js";
// import { Card, Button, Container } from "react-bootstrap";
import Modal from "../Admin/UserModification/Modal";
import CreateNewUser from "../Admin/CreateNewUser";
import axios from "axios";
import { Container } from "react-bootstrap";

import Chat from "../chat/ChatAdmins";
import Fade from "react-reveal/Fade";

const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: #5c3aff;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const Main = ({
  /*collapsed,
  rtl,
  image,*/
  handleToggleSidebar,
  /*handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  showMainView,*/
  handleModal,
  isOpen,
  dashBoardDepartment,
  /*refreshAllUsers,
  currentUsersToTrack,
  allUsers,
  usersToShow,*/
  users,
  TotalMinutes,
  TotalOutGoingCalls,
  TotalIncomingCalls,
  TotalMissedCalls,
  toggle,
  totalUsers,
  // Presence,
  Presence,
  ShowChat,
  scrollSetForGraph,
  // arrayPresence,
  //getAllUsers,
  // Stats
}) => {
  ///const totalUsers = allUsers === undefined ? 0 : allUsers.length;

  const intl = useIntl();

  async function UserCreation(e) {
    e.preventDefault(); // CompanyName, displayName, Licence, AdminEmail , password UserAllowance, OpearationalTime,
    const { userName, email, phone, password, role } = e.target.elements;
    //const displayName = userName;
    try {
      setcreatingUser(true);
      console.log(
        userName.value,
        email.value,
        phone.value,
        password.value,
        role.value
      );

      await app
        .auth()
        .currentUser // .getIdToken()
        // .then(function (idToken) {
        .getIdTokenResult()
        .then((idToken) => {
          let url = BACKEND_BASE_URL + "create_user";

          //console.log(url);

          //let root = idToken.claims.licence;
          //let uid = idToken.claims.user_id;
          console.log("Creating a user");
          const body = {
            userName: userName.value,
            email: email.value,
            phone: phone.value,
            password: password.value,
            role: role.value,
            licence: idToken.claims.Licence,
          };

          axios
            .post(url, body, {
              headers: {
                Authorization: "Bearer " + idToken.token,
                // 'Content-Type' : 'text/plain'
              },
            })
            .then(function (response) {
              //let obj = response;
              console.log(response.data);
              setcreatedUser(true);
              setUserCreationResult(JSON.stringify(response.data.message));
              //getAllUsers();
            })
            .catch(function (error) {
              console.log(error);

              setUserCreationResult(JSON.stringify(error.message));
              setcreatedUser(true);
            })
            .then(function () {
              // always executed
              setcreatingUser(false);
            });
        });
    } catch (error) {
      alert(error);
    }
  }

  // async function UserEdit(uid, newEmail_, oldEmail) {
  //   e.preventDefault(); // CompanyName, displayName, Licence, AdminEmail , password UserAllowance, OpearationalTime,
  //   //const { uid, email, oldEmail } = e.target.elements;
  //   //const displayName = userName;
  //   try {
  //     //setcreatingUser(true);

  //     await app
  //       .auth()
  //       .currentUser
  //       .getIdTokenResult()
  //       .then((idToken) => {
  //         let url = BACKEND_BASE_URL + "update_user/";

  //         console.log(url);

  //         console.log("updating a user");
  //         const body = {
  //           uid = uid,
  //           newEmail = newEmail_,
  //           email: oldEmail,
  //           licence: idToken.claims.Licence,
  //         };

  //         axios
  //           .post(url, body, {
  //             headers: {
  //               Authorization: "Bearer " + idToken.token,
  //               // 'Content-Type' : 'text/plain'
  //             },
  //           })
  //           .then(function (response) {
  //             //let obj = response;
  //             console.log(response.data);
  //             //setcreatedUser(true);
  //             //setUserCreationResult(JSON.stringify(response.data.message));
  //             //getAllUsers();
  //           })
  //           .catch(function (error) {
  //             console.log(error);

  //             //setUserCreationResult(JSON.stringify(error.message));
  //             //setcreatedUser(true);
  //           })
  //           .then(function () {
  //             // always executed
  //             setcreatingUser(false);
  //           });
  //       });
  //   } catch (error) {
  //     alert(error);
  //   }
  // }

  const [creatingUser, setcreatingUser] = useState(false);
  const [createdUser, setcreatedUser] = useState(false);
  const [UserCreationResult, setUserCreationResult] = useState("");

  function resetState() {
    console.log("called resetState");
    if (isOpen) {
      console.log("called resetState in isOpen");

      setcreatingUser(false);
      setcreatedUser(false);
      setUserCreationResult("");
    }
  }

  return (
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      {/* <Container fluid> */}
      <div
        className="btn-toggle tiny-margin"
        onClick={() => handleToggleSidebar(true)}
      >
        <FaBars />
      </div>
      <h5>
        {intl.formatMessage({ id: "Department" })}: '{dashBoardDepartment}'
      </h5>

      <DashBoardHeader
        totalUsers={totalUsers}
        TotalMinutes={TotalMinutes}
        TotalOutGoingCalls={TotalOutGoingCalls}
        TotalIncomingCalls={TotalIncomingCalls}
        TotalMissedCalls={TotalMissedCalls}
        scrollSetForGraph={scrollSetForGraph}
      />

      <UserPresence
        // currentUsersToTrack={currentUsersToTrack}
        // dashBoardDepartment={dashBoardDepartment}
        // allUsers={allUsers}
        users={users}
        // PresenceConditions={PresenceConditions}
        // Presence={Presence}
        Presence={Presence}
        // arrayPresence={arrayPresence}
      />
      <p></p>

      <Fade right when={ShowChat}>
        <Chat />
      </Fade>
      {/* <Card>
          <Card.Body>
            <Card.Body>{getElementsToProcess()}</Card.Body>
          </Card.Body>
        </Card> */}
      {/* render this modal at the end so that it renders at a higher order in dashboard*/}
      <div>
        <Modal
          resetState={resetState}
          isOpen={isOpen}
          handleClose={handleModal}
        >
          <ModalContent>
            {/* <h1> Awesome modal </h1> */}
            <div className="card">
              <CreateNewUser
                onSubmit={UserCreation}
                creatingUser={creatingUser}
                createdUser={createdUser}
                UserCreationResult={UserCreationResult}
                resetState={resetState}
                isOpen={isOpen}
                toggle={toggle}
              />
            </div>
          </ModalContent>
        </Modal>
      </div>

      <footer>
        <small>{intl.formatMessage({ id: "footerCopyRights" })} </small>
        <br />
      </footer>
      {/* </Container> */}
    </main>
  );
};

export default Main;
