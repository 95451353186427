import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { AuthProvider } from "./auth/Auth";
import PrivateRoute from "./PrivateRoute";
// import { IntlProvider } from "react-intl";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import SignUpComponent from "./pages/components/SignUpComponent/SignUpComponent";
// import Main from "./pages/components/etc/Main";
import "./pages/components/SideBar/styles/App.scss";
// import messages from "./International/messages";
// import 'react-pro-sidebar/dist/css/styles.css';

function App() {
  // const [locale, setLocale] = useState("en");

  return (
    <AuthProvider>
      {/* <IntlProvider locale={locale} messages={messages[locale]}> */}
      {/* <Layout setLocale={setLocale} /> */}

      <Router>
        <div>
          <PrivateRoute exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/signup" component={SignUp} /> */}
        </div>
      </Router>
      {/* </IntlProvider> */}
    </AuthProvider>
  );
}

export default App;
