import React, { useState } from "react";
import { useIntl } from "react-intl";
// import Switch from "react-switch";
import { FaBars } from "react-icons/fa";
// import reactLogo from "./assets/logo.svg";
//
import MasterAdminDashBoardHeader from "../DashBoard/DashBoardHeader/MasterAdminDashBoardHeaderMain";
// import Chart from "../ChartManager/Chart";

import Modal from "../Admin/UserModification/Modal";
// import OpenModalButton from "../Admin/UserModification/OpenModalButton";
import styled from "styled-components";
// import CreateNewUser from "../Admin/CreateNewUser";
import CreateNewCompany from "../Admin/MasterAdmin/Companies/CreateNewCompany";
import app, { BACKEND_BASE_URL } from "../../../base.js";
import axios from "axios";
import { Container } from "react-bootstrap";
///////////
import Chat from "../chat/ChatMA";
import Fade from "react-reveal/Fade";
///////////
const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: #5c3aff;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const MainMasterAdmin = ({
  /*collapsed,
  rtl,
  image,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,*/
  handleToggleSidebar,
  handleModal,
  toggle,
  isOpen,
  TotalUsersNumber,
  TotalCompanies,
  TotalCompaniesRemoved,
  TotalUsersRemoved,
  ShowChat,
}) =>
  // {handleToggleSidebar, Teststrin}
  {
    async function CompanyCreation(e) {
      e.preventDefault(); // CompanyName, displayName, Licence, AdminEmail , password UserAllowance, OpearationalTime,
      const {
        CompanyName,
        userName,
        Licence,
        AdminEmail,
        password,
        UserAllowance,
        OpearationalTime,
      } = e.target.elements;
      //const displayName = userName;
      try {
        // await app
        //   .auth()
        //   .signInWithEmailAndPassword(email.value, password.value);
        // history.push("/");
        setcreatingCompany(true);
        // console.log(
        //   "CompanyName: " +
        //     CompanyName.value +
        //     " , AdminEmail: " +
        //     AdminEmail.value +
        //     " , displayName: " +
        //     userName.value +
        //     " , Licence: " +
        //     Licence.value +
        //     " , password: " +
        //     password.value
        // );

        await app
          .auth()
          .currentUser.getIdToken()
          .then(function (idToken) {
            // <------ Check this line
            //alert(idToken); // It shows the Firebase token now
            // console.log(idToken);
            // token = idToken;
            let url = BACKEND_BASE_URL + "create_company";

            const body = {
              CompanyName: CompanyName.value,
              AdminEmail: AdminEmail.value,
              displayName: userName.value,
              Licence: Licence.value,
              password: password.value,
              UserAllowance: UserAllowance.value,
              OpearationalTime: OpearationalTime.value,
            };

            axios
              .post(url, body, {
                headers: {
                  Authorization: "Bearer " + idToken,
                  // 'Content-Type' : 'text/plain'
                },
              })
              .then(function (response) {
                //let obj = response;
                console.log(response.data);
                setcreatedCompany(true);
                setCompanyCreationResult(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);

                setCompanyCreationResult(JSON.stringify(error));
                setcreatedCompany(true);
              })
              .then(function () {
                // always executed
                setcreatingCompany(false);
              });
          });
        //
      } catch (error) {
        alert(error);
      }
    }
    ///////////////////////////////////////////////////
    const intl = useIntl();

    //const [dashBoardDepartment, setDashBoardDepartment] = useState("_");

    const [creatingCompany, setcreatingCompany] = useState(false);
    const [createdCompany, setcreatedCompany] = useState(false);
    const [CompanyCreationResult, setCompanyCreationResult] = useState("");

    function resetState() {
      console.log("called resetState");
      if (isOpen) {
        console.log("called resetState in isOpen");

        setcreatingCompany(false);
        setcreatedCompany(false);
        setCompanyCreationResult("");
      }
    }

    // useEffect(() => {
    //   let TotalCompaniesListener, TotalUsersNumberListener;

    //   try {

    //     TotalCompaniesListener = firestore
    //       .collection("Info")
    //       .doc("TotalCompanies")
    //       .onSnapshot((doc) => {
    //         setTotalCompanies(doc.data().TotalCompaniesCreated);
    //         console.log("TotalCompaniesListener Current data: ", doc.data());
    //       });

    //     TotalUsersNumberListener = firestore
    //       .collection("Info")
    //       .doc("TotalUsers")
    //       .onSnapshot((doc) => {
    //         setTotalUsersNumber(doc.data().TotalUsersCreated);
    //         console.log("TotalUsersListener Current data: ", doc.data());
    //       });
    //     // const rootRef = firebase.database().ref().child('react');.TotalCompaniesCreated
    //     // const speedRef = rootRef.child('speed');

    //     // speedRef.on('value', snap => {
    //     //   this.setState({
    //     //     speed: snap.val()
    //     //   });
    //     // });
    //   } catch (error) {
    //     console.log(error);

    //     alert("error occurred. " + error);
    //   }

    //   return () => {
    //     // db.ref("AdminDashBoardDepartment").off("value", listener);
    //     TotalCompaniesListener();
    //     TotalUsersNumberListener();
    //     console.log(
    //       "Ok about to unmount and remove references on MainMasterAdmin."
    //     );
    //   };
    // }, []);

    return (
      <Container fluid>
        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <MasterAdminDashBoardHeader
          TotalCompanies={TotalCompanies}
          TotalUsersNumber={TotalUsersNumber}
          TotalCompaniesRemoved={TotalCompaniesRemoved}
          TotalUsersRemoved={TotalUsersRemoved}
        />
        {/* <Chart /> */}
        <p></p>
        <p></p> <h5>Companies Overview available at 'Manage Companies' tab.</h5>
        <p></p>
        {/* <h4>
          Instructed not to render more information. Contact developer if you
          want to.
        </h4> */}
        <Fade right when={ShowChat}>
          <Chat />
        </Fade>
        {/*you will render element for {dashBoardDepartment} Departments*/}
        {/* render this modal at the end so that it renders at a higher order of components in dashboard*/}
        <div>
          <Modal
            isOpen={isOpen}
            handleClose={handleModal}
            resetState={resetState}
          >
            <ModalContent>
              <div className="main-content">
                <CreateNewCompany
                  onSubmit={CompanyCreation}
                  creatingCompany={creatingCompany}
                  createdCompany={createdCompany}
                  CompanyCreationResult={CompanyCreationResult}
                  toggle={toggle}
                  resetState={resetState}
                />
              </div>
            </ModalContent>
          </Modal>
        </div>
        <footer>
          <small>{intl.formatMessage({ id: "footerCopyRights" })} </small>
          <br />
        </footer>
      </Container>
    );
  };

export default MainMasterAdmin;

//G:\Freelancer\React\Mov365\mov365
