import React from "react";
import { Form } from "react-bootstrap";
import "./login.css";

class EntryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: "logIn",
      pending: false, //ToDo add feature.
      username: "",
      // inputLicence: "",
      valid: false,
      loginvalid: false,
      resetvalid: false,
      loginEmailValid: false,
      loginPassValid: false,

      licencevalid: false,
      emailvalid: false,
      passwordvalid: false,
    };

    // this.handleEmailChange = this.handleEmailChange.bind(this);
    // this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLogIn = this.handleLogIn.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.handlePWReset = this.handlePWReset.bind(this);
    // this.onChangeValid = this.onChangeValid.bind(this);
  }

  // handleChangeVnum(event) {
  //   this.setState({ vnum: event.target.value });
  // }

  // handleChangeShortDesc(event) {
  //     this.setState({shortDesc: event.target.value});
  // }
  // handleEmailChange(event) {
  //   this.setState({email: event.target.value});
  // }
  // handlePasswordChange(event) {
  //   this.setState({password: event.target.value});
  // }

  handleLogIn(event) {
    this.setState({ pending: true });
    this.props.handleLogin(event);
  }

  handleSignUp(event) {
    this.props.handleSignUp(event);
  }

  handlePWReset(event) {
    this.props.handlePWReset(event);
  }

  changeView = (view) => {
    this.setState({
      currentView: view,
    });
  };

  // onChangeValid = (event) => {
  //   let value = event.target.value;

  //   let _valid = value.length > 5;

  //   this.setState({
  //     valid: _valid,
  //   });
  // };

  currentView = () => {
    switch (this.state.currentView) {
      case "signUp":
        return (
          <Form onSubmit={this.handleSignUp}>
            <h3>MOV365</h3>

            <fieldset>
              <legend>Request Your Account</legend>
              <ul>
                <li>
                  <label htmlFor="username">Username:</label>
                  <input
                    type="name"
                    id="username"
                    value={this.state.username}
                    required
                    onChange={(e) => {
                      let username = e.target.value;

                      username = username.replace(/[^A-Za-z_0-9]/gi, "");

                      this.setState({
                        username,
                      });
                    }}
                  />
                </li>
                <li>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    required
                    onChange={(event) => {
                      let value = event.target.value;
                      var re = /\S+@\S+\.\S+/;
                      let _valid = value.length > 5 && re.test(value);

                      this.setState({
                        emailvalid: _valid,
                        valid:
                          this.state.emailvalid &&
                          this.state.licencevalid &&
                          this.state.passwordvalid,
                      });
                    }}
                  />
                </li>
                <li>
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    required
                    onChange={(event) => {
                      let value = event.target.value;

                      let _valid = value.length > 5;

                      this.setState({
                        passwordvalid: _valid,
                        valid:
                          this.state.emailvalid &&
                          this.state.licencevalid &&
                          this.state.passwordvalid,
                      });
                    }}
                  />
                </li>
                <li>
                  <label htmlFor="licence">Licence:</label>
                  <input
                    type="text"
                    id="licence"
                    required
                    onChange={(event) => {
                      let value = event.target.value;

                      let _valid = value.length > 7;

                      this.setState({
                        licencevalid: _valid,
                        valid:
                          this.state.emailvalid &&
                          this.state.licencevalid &&
                          this.state.passwordvalid,
                      });
                    }}
                  />
                </li>
              </ul>
            </fieldset>
            <button disabled={!this.state.valid}>
              Request Your Account for Approval
            </button>
            <button type="button" onClick={() => this.changeView("logIn")}>
              Have an Account?
            </button>
          </Form>
        );
      case "logIn":
        return (
          <form onSubmit={this.handleLogIn}>
            <h3>MOV365</h3>
            <fieldset>
              <legend>Log In</legend>
              <ul>
                <li>
                  <label htmlFor="email">Email:</label>
                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                    onChange={(event) => {
                      let value = event.target.value;
                      var re = /\S+@\S+\.\S+/;
                      let _valid = value.length > 3 && re.test(value);

                      this.setState({
                        loginEmailValid: _valid,
                        loginvalid: this.state.loginPassValid && _valid,
                      });
                    }}
                  />
                </li>
                <li>
                  <label htmlFor="password">Password:</label>
                  <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    required
                    onChange={(event) => {
                      let value = event.target.value;

                      let _valid =
                        value.length > 5 && this.state.loginEmailValid;

                      this.setState({
                        loginPassValid: _valid,
                        loginvalid: this.state.loginEmailValid && _valid,
                      });
                    }}
                  />
                </li>
                <li>
                  <i />
                  {this.state.pending ? (
                    <button>Log In ...</button>
                  ) : (
                    <button type="submit" disabled={!this.state.loginvalid}>
                      LogIn
                    </button>
                  )}
                </li>
              </ul>
            </fieldset>
            <button onClick={() => this.changeView("PWReset")}>
              Forgot Password?
            </button>
            {/* <button type="button" onClick={() => this.changeView("signUp")}>
              Request an Account
            </button> */}
          </form>
        );
      case "PWReset":
        return (
          <form onSubmit={this.handlePWReset}>
            <h3>MOV365</h3>
            <fieldset>
              <legend>Password Reset</legend>
              <ul>
                <li>
                  <em>A reset link will be sent to your inbox!</em>
                </li>
                <li>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    required
                    onChange={(event) => {
                      let value = event.target.value;
                      var re = /\S+@\S+\.\S+/;
                      let _valid = value.length > 3 && re.test(value);

                      this.setState({
                        loginvalid: _valid,
                      });
                    }}
                  />
                </li>
              </ul>
            </fieldset>
            <button disabled={!this.state.loginvalid}>Send Reset Link</button>
            <button type="button" onClick={() => this.changeView("logIn")}>
              Go Back
            </button>
          </form>
        );
      default:
        break;
    }
  };

  render() {
    return <section id="entry-page">{this.currentView()}</section>;
  }
}

export default EntryPage;
