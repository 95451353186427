import React, { useEffect, useState, useRef } from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { FaBars } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { Dropdown, Card, Container, Button, Modal, Row } from "react-bootstrap";

function UserGraphs({
  Stats,
  usersToShow,
  handleToggleSidebar,
  dashBoardDepartment,
  showLogsView,
  whereToScrollTo,
}) {
  //const [chartData, setChartData] = useState({});
  const downloadButtonsRef = useRef();
  const totalMinuteDataRef = useRef();
  const totalIncomingCallsDataRef = useRef();
  const totalOutGoingCallsDataRef = useRef();
  const totalMissedCallsDataRef = useRef();

  // const download = "download";
  const totalMin = "totalMin";
  const totalInc = "totalInc";
  const totalOut = "totalOut";
  const totalMis = "totalMis";

  const [viewAs, setViewAs] = useState("Bar");

  const [show, setShow] = useState(false);

  const [myView, setMyView] = useState("");
  const [fullView, setFullView] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //   const usersData = usersToShow.map((user) =>
  //   allUsers.find((obj) => obj.id == user)
  // );

  useEffect(() => {
    if (whereToScrollTo != null)
      setTimeout(() => {
        switch (whereToScrollTo) {
          case totalMin:
            totalMinuteDataRef.current.scrollIntoView({
              behavior: "smooth",
            });
            break;
          case totalInc:
            totalIncomingCallsDataRef.current.scrollIntoView({
              behavior: "smooth",
            });
            break;
          case totalOut:
            totalOutGoingCallsDataRef.current.scrollIntoView({
              behavior: "smooth",
            });
            break;
          case totalMis:
            totalMissedCallsDataRef.current.scrollIntoView({
              behavior: "smooth",
            });
            break;
          default:
            break;
        }
      }, 500);
  });

  function changeViewAs(params) {
    setViewAs(params);
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  function getRandomRgb() {
    var num = Math.round(0xffffff * Math.random());
    var r = num >> 16;
    var g = num >> 8 && 255;
    var b = num & 255;
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }

  var backgroundColors = [];

  function preparebackgroundcolors(length) {
    backgroundColors = Array(length).fill("");

    for (var i = 0; i < length; i++) {
      backgroundColors[i] = getRandomRgb();
    }

    return backgroundColors;
  }

  // var x_backgroundColors = [
  //   "rgba(255, 99, 132, 0.6)",
  //   "rgba(54, 162, 235, 0.6)",
  //   "rgba(255, 206, 86, 0.6)",
  //   "rgba(75, 192, 192, 0.6)",
  //   "rgba(153, 102, 255, 0.6)",
  //   "rgba(255, 159, 64, 0.6)",
  //   "rgba(255, 99, 132, 0.6)",
  // ];

  function prepareanddownload(
    labels,
    totalMinuteData,
    totalIncomingCallsData,
    totalOutGoingCallsData,
    totalMissedCallsData
  ) {
    setMyView(<div>Preparing data.</div>);
    let nextLabels = [
      "",
      "User Name",
      "Total Minute Data",
      "Total IncomingCalls Data",
      "Total MissedCalls Data",
      "Total OutGoingCalls Data",
    ];

    var arr = Array.from(Array(labels.length), () => new Array(6));

    labels.map((e, i) => {
      arr[i][1] = e;
      arr[i][0] = i;
      arr[i][2] = totalMinuteData[i];
      arr[i][3] = totalIncomingCallsData[i];
      arr[i][4] = totalMissedCallsData[i];
      arr[i][5] = totalOutGoingCallsData[i];
    });

    function downloader() {
      console.log("very nice", arr);
    }

    setMyView(
      <div>
        <CSVLink
          data={arr}
          headers={nextLabels}
          filename={
            "mov365Pro_Overview_Data_" +
            new Date(Date.now()).toLocaleString("en-us") +
            ".csv"
          }
          className="btn btn-primary"
          target="_blank"
        >
          Download All Data (Overview)
        </CSVLink>
        <p></p>
      </div>
    );
  }

  function populateData() {
    let labels = [];
    let totalMinuteData = [];
    let totalIncomingCallsData = []; //totalMissedCalls totalOutGoingCalls
    let totalMissedCallsData = [];
    let totalOutGoingCallsData = [];

    if (usersToShow === undefined || usersToShow.length === 0) {
      return;
    }

    Stats.map((user) => {
      totalMinuteData.push(user.totalMinute);
      totalIncomingCallsData.push(user.totalIncomingCalls);
      totalMissedCallsData.push(user.totalMissedCalls);
      totalOutGoingCallsData.push(user.totalOutGoingCalls);
    });

    usersToShow.map((user) => {
      labels.push(user.displayName);
    });

    preparebackgroundcolors(labels.length);

    const totalMinuteDataChartData = {
      labels: labels,
      datasets: [
        {
          label: "Total Call Minutes",
          data: totalMinuteData,
          backgroundColor: backgroundColors,
        },
      ],
    };

    const totalIncomingCallsChartData = {
      labels: labels,
      datasets: [
        {
          label: "Total Incoming Calls Chart",
          backgroundColor: backgroundColors,
          data: totalIncomingCallsData,
        },
      ],
    };

    const totalMissedCallsChartData = {
      labels: labels,
      datasets: [
        {
          label: "Total Missed Calls Chart",
          backgroundColor: backgroundColors,
          data: totalMissedCallsData,
        },
      ],
    };

    const totalOutGoingCallsChartData = {
      labels: labels,
      datasets: [
        {
          label: "Total OutGoing Calls Chart",
          backgroundColor: backgroundColors,
          data: totalOutGoingCallsData,
        },
      ],
    };

    if (backgroundColors.length === labels.length) {
      console.log("no issue happened");
    } else {
      console.log(
        "some issue happened",
        backgroundColors.length,
        labels.length
      );
    }

    function DownloadButton() {
      return (
        <Card ref={downloadButtonsRef}>
          <Card.Header>User Stats Overview</Card.Header>
          <Card.Body>
            <CSVLink
              data={[totalOutGoingCallsData]}
              headers={labels}
              filename={
                "mov365Pro_total_OutGoingCallsData_" +
                new Date(Date.now()).toLocaleString("en-us") +
                ".csv"
              }
              className="btn btn-primary"
              target="_blank"
            >
              Download Total OutGoing Calls Data
            </CSVLink>
            <span>&nbsp;&nbsp;</span>
            <CSVLink
              data={[totalIncomingCallsData]}
              headers={labels}
              filename={
                "mov365Pro_total_IncomingCallsData_" +
                new Date(Date.now()).toLocaleString("en-us") +
                ".csv"
              }
              className="btn btn-primary"
              target="_blank"
            >
              Download Total Incoming Calls Data
            </CSVLink>
            <span>&nbsp;&nbsp;</span>
            <CSVLink
              data={[totalMissedCallsData]}
              headers={labels}
              filename={
                "mov365Pro_total_MissedCallsData_" +
                new Date(Date.now()).toLocaleString("en-us") +
                ".csv"
              }
              className="btn btn-primary"
              target="_blank"
            >
              Download Total Missed Calls Data
            </CSVLink>
            <span>&nbsp;&nbsp;</span>
            <CSVLink
              data={[totalMinuteData]}
              headers={labels}
              filename={
                "mov365Pro_total_MinuteData_" +
                new Date(Date.now()).toLocaleString("en-us") +
                ".csv"
              }
              className="btn btn-primary"
              target="_blank"
            >
              Download Total Minutes Data
            </CSVLink>
            <span>&nbsp;&nbsp;</span>

            <p></p>

            <Button
              variant="primary"
              onClick={() => {
                prepareanddownload(
                  labels,
                  totalMinuteData,
                  totalIncomingCallsData,
                  totalOutGoingCallsData,
                  totalMissedCallsData
                );
                handleShow();
              }}
            >
              Download Overview Data
            </Button>
            <p></p>
            <Button onClick={showLogsView}>
              Go to Advanced Logs Presentation page
            </Button>
          </Card.Body>
        </Card>
      );
    }

    function toggleView(params) {
      setFullView(!fullView);
    }

    function getNavBarButtons() {
      return (
        <div className="sticky-wrapper">
          <div className="fixed-man">
            <Dropdown>
              <Button variant="success">Show As</Button>

              <Dropdown.Toggle
                split
                variant="success"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeViewAs("Bar")}>
                  Bar Chart
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeViewAs("Line")}>
                  Line Chart
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeViewAs("Doughnut")}>
                  Doughnut Chart
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="fixed-man righty">
            <Dropdown>
              <Button variant="success">Goto</Button>

              <Dropdown.Toggle
                split
                variant="success"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    try {
                      totalMinuteDataRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    } catch (error) {}
                  }}
                >
                  Total Minute Data
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    try {
                      totalIncomingCallsDataRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    } catch (error) {}
                  }}
                >
                  Total Incoming Calls
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    try {
                      totalMissedCallsDataRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    } catch (error) {}
                  }}
                >
                  Total Missed Calls
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    try {
                      totalOutGoingCallsDataRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    } catch (error) {}
                  }}
                >
                  Total OutGoing Calls
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    try {
                      downloadButtonsRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    } catch (error) {}
                  }}
                >
                  Downloads Section
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    }
    switch (viewAs) {
      case "Bar":
        return (
          <div>
            {getNavBarButtons()}
            <p></p>
            <Card
              // className="fullView"
              ref={totalMinuteDataRef}
            >
              <Bar
                // className={fullView ? "fullView" : ""}
                // className="fullView"
                data={totalMinuteDataChartData}
                options={options}
              />
            </Card>
            <span>&nbsp;&nbsp;</span>
            <Card ref={totalIncomingCallsDataRef}>
              <Bar data={totalIncomingCallsChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>
            <Card ref={totalMissedCallsDataRef}>
              <Bar data={totalMissedCallsChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>
            <Card ref={totalOutGoingCallsDataRef}>
              <Bar data={totalOutGoingCallsChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>

            {DownloadButton()}
          </div>
        );
      case "Line":
        return (
          <div>
            {getNavBarButtons()}

            <p></p>

            <Card ref={totalMinuteDataRef}>
              <Line data={totalMinuteDataChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>
            <Card ref={totalOutGoingCallsDataRef}>
              <Line data={totalIncomingCallsChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>
            <Card ref={totalMissedCallsDataRef}>
              <Line data={totalMissedCallsChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>
            <Card ref={totalOutGoingCallsDataRef}>
              <Line data={totalOutGoingCallsChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>

            {DownloadButton()}
          </div>
        );
      case "Doughnut":
        return (
          <div>
            {getNavBarButtons()}

            <p></p>

            <Card ref={totalMinuteDataRef}>
              <div className="text-center border-bottom">Total Minute</div>
              <Doughnut data={totalMinuteDataChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>
            <Card ref={totalIncomingCallsDataRef}>
              <div className="text-center border-bottom">
                Total Incoming Calls
              </div>
              <Doughnut data={totalIncomingCallsChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>
            <Card ref={totalMissedCallsDataRef}>
              <div className="text-center border-bottom">
                Total Missed Calls
              </div>
              <Doughnut data={totalMissedCallsChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>
            <Card ref={totalOutGoingCallsDataRef}>
              <div className="text-center border-bottom">
                Total Outgoing Calls
              </div>
              <Doughnut data={totalOutGoingCallsChartData} options={options} />
            </Card>
            <span>&nbsp;&nbsp;</span>

            {DownloadButton()}
          </div>
        );
      default:
        return <div>Some error occurred while processing Graphs.</div>;
    }
  }
  /*  let headers = [
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
    { label: "info", key: "info" },
  ];

  let data = [
    { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
    {
      firstname: "Yezzi",
      lastname: "Min l3b",
      email: "ymin@cocococo.com",
      info: "Stuff",
    },
  ];
*/
  return (
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      {/* <Container fluid className="justify-content-md-center"> */}
      <div
        className="btn-toggle tiny-margin"
        onClick={() => handleToggleSidebar(true)}
      >
        <FaBars />
      </div>
      <div>
        <h5>Department: '{dashBoardDepartment}'</h5>
      </div>
      {populateData()}

      {/* <Button variant="secondary">
        <CSVLink data={data} headers={headers}>
          Download me
        </CSVLink>
      </Button>

      <Button variant="success" onClick={TESTFunc}></Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Download All Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>{myView}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* </Container> */}
    </main>
  );
}

export default UserGraphs;
