import React, { useEffect, useState, useRef } from "react";
import Aside from "../../../SideBar/Aside";
import Main from "../../../SideBar/MainAdmin";
import SelectDepartment from "../../../Admin/Departments/SelectDepartment";
import app, { firestore, FieldValue } from "../../../../../base";
import UserManagement from "../../../Admin/UserManagement";
// import { Button } from "react-bootstrap";
// import Chart from "../../../ChartManager/UserChart";
import Profile from "../../../Profile/Profile";
// import UserPresence from "../../../Presence/UserPresence";
import UserGraphs from "../../../ChartManager/UserGraphs";
import AdvancedDataPresentation from "./AdvancedDataPresentation/AdvancedDataPresentation";
import { useIntl } from "react-intl";

function AdminDashBoard({ locale, setLocale }) {
  const intl = useIntl();

  const _MainDashBoard = "MainDashBoard",
    _DepartmentsView = "Departments",
    _GraphsView = "GraphsView",
    _ProfileView = "ProfileView",
    _AdvancedDataPresentation = "AdvancedDataPresentation",
    _UsersView = "Users";

  ///

  var [testVar, setTestVar] = useState(1);

  ///
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [ShowMainView, setShowMainView] = useState(_MainDashBoard);
  const [dashBoardDepartment, setDashBoardDepartment] = useState("-");
  const [dashBoardBoardsList, setdashBoardBoardsList] = useState([]);
  const [currentUsersToTrack, setCurrentUsersToTrack] = useState([]);
  const [currentDocRoot, setDocRoot] = useState("");
  const [departmentModifying, setdepartmentModifying] = useState(false);
  // const [allUsers, setallUsers] = useState([]);
  // const allUsers = useRef([]).current;
  const assignedFromAll = useRef(false);
  const FromStartUp = useRef("FromStartUp");

  const [TotalMinutes, setTotalMinutes] = useState(0);
  const [TotalIncomingCalls, setTotalIncomingCalls] = useState(0);
  const [TotalOutGoingCalls, setTotalOutGoingCalls] = useState(0);
  const [TotalMissedCalls, setTotalMissedCalls] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  // const [PresenceConditions, setPresenceConditions] = useState({});

  const OwnSelf = useRef({});
  const OwnSelfStats = useRef({});

  const OwnSelfID = useRef("");

  const listeners = useRef([]).current;
  const StatsListeners = useRef([]).current;
  const PresenceConditionListeners = useRef([]).current;

  const users = useRef([]).current;
  const Stats = useRef([]).current;
  // const Presence = useRef([]).current;
  // const Presence = useRef(new Map());
  // const PresenceArray = useRef([]).current;
  // const presenceCondition = useRef(new Map());
  // const [Presence, setPresence] = useState(new Map());
  const arrayPresence = useRef({});

  const [usersToShow, setusersToShow] = useState([]);
  //const [usersToShowUnSorted, setusersToShowUnSorted] = useState([]);

  const [Presence, setPresence] = useState({});
  // const [mValues, setmValues] = useState({});
  // const totalMin = "totalMin";
  // const totalInc = "totalInc";
  // const totalOut = "totalOut";
  // const totalMis = "totalMis";

  const whereToScrollTo = useRef("");

  const attachListener = (listener) => listeners.push(listener);
  const attachPresenceConditionListeners = (listener) =>
    PresenceConditionListeners.push(listener);

  const dettachListeners = () =>
    StatsListeners.forEach((listener) => listener());
  const dettachPresenceConditionListeners = () =>
    PresenceConditionListeners.forEach((listener) => listener());
  //let dashBoardListener;
  const dashBoardListener = useRef(null);
  const testNumber = useRef([0, 0]).current;
  const [ShowChat, setShowChat] = useState(false);

  ////////////////
  {
    // const [chartData, setChartData] = useState({
    //   labels: [
    //     "Boston",
    //     "Worcester",
    //     "Springfield",
    //     "Lowell",
    //     "Cambridge",
    //     "New Bedford",
    //   ],
    //   datasets: [
    //     {
    //       fill: false,
    //       label: "Population",
    //       data: [617594, 181045, 153060, 106519, 105162, 95072],
    //       backgroundColor: [
    //         "rgba(255, 99, 132, 0.6)",
    //         "rgba(54, 162, 235, 0.6)",
    //         "rgba(255, 206, 86, 0.6)",
    //         "rgba(75, 192, 192, 0.6)",
    //         "rgba(153, 102, 255, 0.6)",
    //         "rgba(255, 159, 64, 0.6)",
    //         "rgba(255, 99, 132, 0.6)",
    //       ],
    //     },
    //   ],
    // });
  }

  //let listeners = [];
  async function spawnListeners(root, list) {
    testNumber[0]++;
    // console.log(testNumber[0], "spawing parameter listeners with : ", root);
    if (listeners.length !== 0) {
      // console.log("Removing some listensers");

      await removeListeners();
      users.length = 0;

      // console.log(" listensers", listeners.length, " users ", users.length);
    }

    let CurrentParentRef = firestore
      .collection("Companies")
      .doc(root)
      .collection("Users");

    ////////////////////
    await spawnerUserProfiles(CurrentParentRef, list);

    // console.log("users len: ", users.length, " , users: ", users);
    ////////////////////////////////
    await spawnerStats(CurrentParentRef, list);
    ////////////////////////////////////////
    await spawnPresenceConditions(CurrentParentRef, list);

    // if (FromStartUp.current === "FromStartUp") {
    //   console.log("This should be from startupCall");
    //   await rerenderThings();
    //   FromStartUp.current = "nFromStartUp";
    //   return;
    // } else {
    //   console.log("Ok, its better.");
    // }
  }

  async function removeListeners() {
    await listeners.forEach((listener) => listener());
    listeners.length = 0;
  }

  async function rerenderThings() {
    testNumber[1]++;

    // console.log(testNumber[1], "Executing rerenderThings...");
    // return;
    let TotalMinutes = 0,
      TotalIncomingCalls = 0,
      TotalMissedCalls = 0,
      TotalOutGoingCalls = 0;
    Stats.forEach((element) => {
      // TotalMinutes.current += element.totalMinute;
      // TotalIncomingCalls.current += element.totalIncomingCalls;
      // TotalOutGoingCalls.current += element.totalOutGoingCalls;
      if (element.totalMinute) {
        TotalMinutes += Number(element.totalMinute);
        // console.log(
        //   "hi dude, check this out: totalMinute: ",
        //   element.totalMinute
        // );
      }
      if (element.totalIncomingCalls) {
        TotalIncomingCalls += Number(element.totalIncomingCalls);
        // console.log(
        //   "hi dude, check this out: totalIncomingCalls: ",
        //   element.totalIncomingCalls
        // );
      }
      if (element.totalMissedCalls) {
        TotalMissedCalls += Number(element.totalMissedCalls);
        // console.log(
        //   "hi dude, check this out: totalMissedCalls: ",
        //   element.totalMissedCalls
        // );
      }
      if (element.totalOutGoingCalls) {
        TotalOutGoingCalls += Number(element.totalOutGoingCalls);
        // console.log(
        //   "hi dude, check this out: totalOutGoingCalls: ",
        //   element.totalOutGoingCalls
        // );
      }
    });

    setTotalOutGoingCalls(TotalOutGoingCalls);
    setTotalIncomingCalls(TotalIncomingCalls);
    setTotalMinutes(TotalMinutes);
    setTotalMissedCalls(TotalMissedCalls);
    setTotalUsers(Stats.length);

    // console.log(
    //   " check this out: ",
    //   TotalOutGoingCalls,
    //   TotalIncomingCalls,
    //   TotalMinutes,
    //   JSON.stringify(Stats)
    // );
  }
  async function spawnPresenceConditions(CurrentParentRef, list) {
    // Presence.length = 0;
    // Presence.current = {};

    // await Promise.all(
    for (let index = 0; index < list.length; index++) {
      // list.map(async (element) => {
      // Presence.current[element] = {};
      // setArrayPresence([]);
      let listener = CurrentParentRef.doc(list[index])
        .collection("presenceCondition")
        .doc("presenceCondition")
        .onSnapshot((doc) => {
          // const uid = element;
          const uid = list[index];

          if (doc) {
            // const uid = element;
            // // Presence.current[uid]
            // let obj = {};
            // obj[uid] = {
            //   presenceCondition: doc.data().presenceCondition,
            // };
            // // console.log(
            // //         "watch here - - ",

            // //         " , Presence.current[uid] --- ",
            // //         Presence.current[uid]
            // //       );
            // PresenceArray.push(obj);
            // setPresenceConditions(...PresenceArray);

            // setArrayPresence((prev) => [
            //   ...prev,
            //   { [uid]: doc.data().presenceCondition },
            // ]); //array of objects

            // presenceCondition.current.set(uid, doc.data().presenceCondition);

            // Presence.current.set("uid", "doc.data().presenceCondition");
            // console.log(
            //   doc.data().presenceCondition,
            //   uid,
            //   " --- Prev --- ",
            //   JSON.stringify(Presence[uid])
            // );

            Presence[uid] = doc.data().presenceCondition;

            // console.log("Presence[uid] - ", JSON.stringify(Presence[uid]));

            // setPresence(presenceCondition.current);

            arrayPresence.current = {
              ...arrayPresence.current,
              [uid]: doc.data().presenceCondition,
            };

            setPresence(arrayPresence.current);

            // console.log("Presence: ", Presence);
          }
        });
      attachPresenceConditionListeners(listener);
    }
    // )
    // );
  }

  async function spawnerUserProfiles(CurrentParentRef, list) {
    // for (let index = 0; index < list.length; index++) {
    users.length = 0;
    await Promise.all(
      list.map(async (element) => {
        let listener = CurrentParentRef.doc(element).onSnapshot((doc) => {
          //setusersUpdating(true);
          if (element === OwnSelfID.current) {
            OwnSelf.current = doc.data();
            // console.log("OwnselfStats: ", OwnSelf.current);
            // return;
          }
          // console.log("some data is changed in spawnerUserProfiles");
          //let id = allUsers.find((obj) => obj.id == doc.id);
          //allUsers[id] = { id: doc.id, ...doc.data() };
          //setShowMainView(ShowMainView);
          let index1 = users.findIndex((x) => x.uid === doc.id); // users.find((obj) => obj.id ==  doc.id)
          if (index1 === -1) {
            // console.log("index1 -1 , could be: ", doc.id);

            users.push(doc.data());
          } else {
            // console.log("element has been found at index: ", index1);
            users[index1] = doc.data();
          }

          //Creates an object map of id to object in arr1

          //Updates the object with corresponding id in arr1Map from arr2,
          //creates a new object if none exists (upsert)

          // users.push(doc.data());
          // console.log("before sort: ", users);
          // users.sort();
          // console.log("after sort: ", users);

          //sroting users by name alphabetically.
          //setusersToShowUnSorted([...users]);
          var tempStorage = [...users];
          tempStorage.sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          );
          setusersToShow([...tempStorage]);

          // console.log("seeeee: ", JSON.stringify(doc.data().presenceCondition));

          //console.log(" usersToShow: ", JSON.stringify(usersToShow));
          //console.log(JSON.stringify(users === usersToShow));

          //console.log(" _users: ", JSON.stringify(users));
        });
        attachListener(listener);
      })
    );

    return; // below code is not used. Kept for
    /*
    await list.forEach((element) => {
      let listener = CurrentParentRef.doc(element).onSnapshot((doc) => {
        //setusersUpdating(true);

        console.log("some data is changed in spawnerUserProfiles");
        //let id = allUsers.find((obj) => obj.id == doc.id);
        //allUsers[id] = { id: doc.id, ...doc.data() };
        //setShowMainView(ShowMainView);
        let index1 = users.findIndex((x) => x.uid === doc.id); // users.find((obj) => obj.id ==  doc.id)
        if (index1 === -1) {
          console.log("index1 -1 , could be: ", doc.id);

          users.push(doc.data());
        } else {
          console.log("element has been found at index: ", index1);
          users[index1] = doc.data();
        }

        //Creates an object map of id to object in arr1

        //Updates the object with corresponding id in arr1Map from arr2,
        //creates a new object if none exists (upsert)

        //users.push(doc.data());
        setusersToShow([...users]);
        // console.log("seeeee: ", JSON.stringify(doc.data().presenceCondition));

        //console.log(" usersToShow: ", JSON.stringify(usersToShow));
        //console.log(JSON.stringify(users === usersToShow));

        //console.log(" _users: ", JSON.stringify(users));
      });
      attachListener(listener);
      // console.log(" ___ listeners[index] : ", listeners[index]);
    });
    console.log("Finished Spawning users profiles.......................");
    */
  }

  async function spawnerStats(CurrentParentRef, list) {
    Stats.length = 0;
    //Stats = [{}];
    await list.forEach((element, index) => {
      let listener = CurrentParentRef.doc(element)
        .collection("Stats")
        .doc("Stats")
        .onSnapshot((doc) => {
          if (element === OwnSelfID.current) {
            OwnSelfStats.current = doc.data();
            // console.log("OwnselfStats: ", OwnSelfStats.current);
            // return;
          }
          // let index1 = Stats.findIndex((x) => x.uid === doc.id); // users.find((obj) => obj.id ==  doc.id)
          // if (index1 === -1) {
          //   console.log("index1 -1 , could be: ", doc.id);

          //   Stats.push({ id: doc.id, ...doc.data() });
          // } else {
          //   console.log("element has been found at index: ", index1);
          //   Stats[index1] = { id: doc.id, ...doc.data() };
          // }
          const myIndex = index;
          // console.log("Got some change in users stats...", index, list.length);

          let index1 = Stats.findIndex((x) => x.id === myIndex); // users.find((obj) => obj.id ==  doc.id)
          if (index1 === -1) {
            // console.log("index1 -1 , could be: ", myIndex);

            Stats.push({ id: myIndex, ...doc.data() });
          } else {
            // console.log("element has been found at index: ", index1);
            Stats[index1] = { id: myIndex, ...doc.data() };
          }
          //Stats.push({ id: index, ...doc.data() });

          if (index >= list.length - 1) {
            // console.log("Ok fine...");

            FromStartUp.current = "nFromStartUp";
          }
          if (FromStartUp.current === "nFromStartUp") {
            //console.log("Should not be from startUpCall", FromStartUp.current);
            rerenderThings();
          }
        });
      StatsListeners.push(listener);
    });
  }

  // async function processUsersToTrackNow() {
  //   if (allUsers.length === 0) {
  //     console.log("allUsers.length === 0");

  //     return;
  //   }
  //   users.current = currentUsersToTrack.map((user) =>
  //     allUsers.find((obj) => obj.id == user)
  //   );

  //   console.log("We got users now: ", users.current);
  // }
  {
    // async function getAllUsers(root, uid) {
    //   let snapshot;
    //   if (root === undefined) {
    //     if (currentDocRoot !== undefined) {
    //       snapshot = firestore
    //         .collection("Companies")
    //         .doc(currentDocRoot)
    //         .collection("Users");
    //     } else return;
    //   } else {
    //     snapshot = firestore
    //       .collection("Companies")
    //       .doc(root)
    //       .collection("Users");
    //   }
    //   // const snapshot = await firestore
    //   //   .collection("Companies")
    //   //   .doc(root)
    //   //   .collection("Users");
    //   // .get();
    //   // const actualData = snapshot.docs.map((doc) => doc.data());
    //   // setAllUsers(actualData);
    //   // const tempDoc = [];
    //   ////////////////////////////
    //   // TotalMinutes.current = 0;
    //   // allUsers.length = 0;
    //   // TotalOutGoingCalls.current = 0;
    //   // TotalIncomingCalls.current = 0;
    //   // setallUsers([]);
    //   await snapshot.get().then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       // TotalMinutes.current += doc.data().totalMinute;
    //       // TotalIncomingCalls.current += doc.data().totalIncomingCalls;
    //       // TotalOutGoingCalls.current += doc.data().totalOutGoingCalls;
    //       // console.log(
    //       //   TotalMinutes.current,
    //       //   TotalIncomingCalls.current,
    //       //   TotalOutGoingCalls.current
    //       // );
    //       if (doc.id === uid) {
    //         OwnSelf.current = doc.data();
    //       }
    //       allUsers.push({ id: doc.id, ...doc.data() });
    //       // tempDoc.push({ id: doc.id, ...doc.data() });
    //       // setallUsers(tempDoc);
    //     });
    //     //console.log("allUsers", JSON.stringify(allUsers));
    //     //setAllUsers(tempDoc);
    //     // tempDoc.forEach((item) => {
    //     //   console.log("item id: ", item.id, " , displayName: ", item.displayName);
    //     // });
    //   });
    //   //console.log(JSON.stringify(tempDoc));
    //   //return snapshot.docs.map(doc => doc.data());
    // }
  }
  async function dashBoardListenerFunc(root, p) {
    // console.log("could be something in: ", root, " and ", p);
    dashBoardListener.current = await firestore
      .collection("Companies")
      .doc(root)
      .collection("departments")
      .doc(p)
      .onSnapshot((doc) => {
        //console.log("Current data: ", doc.data());

        //cant remove this and lsitener started from the app start. so break unwanted change.
        // if (assignedFromAll.current) {
        //   console.log("assignedFromAll");
        //   assignedFromAll.current = false;
        //   return;
        // }

        let data = doc.data();
        if (data) {
          let depts = [];
          data.users.map((d) => {
            depts.push(d);
          });

          // console.log("after sort: ", depts);
          setCurrentUsersToTrack(depts);
          // setcurrentDashBoardBoard(depts);
          spawnListeners(root, depts);
          // console.log(
          //   "currentDashBoard - ",
          //   root,
          //   " ,user in this dept : '",
          //   p,
          //   "' are: ",
          //   depts
          // );
        } else {
          setCurrentUsersToTrack([]);
          // users.length = 0;
          console.log("data === undefined : ", data);
          return;
        }

        // console.log("populated users : ", users.current);
      });
  }
  useEffect(() => {
    let currentDashBoardListener, dashBoardDepartmentsListListener;
    //console.log("useEffect...");

    let root = "";

    try {
      // listener = db
      //   .ref("AdminDashBoardDepartment")
      //   .on("value", (snapshot) => {
      //     let data = snapshot.val();
      //     setDashBoardDepartment(data.currentDashBoard);

      //     console.log(snapshot.val());
      //   });

      // const rootRef = firebase.database().ref().child('react');
      // const speedRef = rootRef.child('speed');

      // speedRef.on('value', snap => {
      //   this.setState({
      //     speed: snap.val()
      //   });
      // });
      app
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          let uid = idTokenResult.claims.user_id;
          root = idTokenResult.claims.Licence;
          setDocRoot(root);
          // getAllUsers(root, uid);
          OwnSelfID.current = uid;
          if (currentDashBoardListener) {
            // console.log("removing currentDashBoardListener");
            currentDashBoardListener();
          }
          currentDashBoardListener = firestore
            .collection("Companies")
            .doc(root)
            .collection("currentDashBoard")
            .doc("currentDashBoard")
            .onSnapshot((doc) => {
              //console.log("Current data: ", doc.data());
              let data = doc.data();
              if (data) {
                let currentDashBoard = data.currentDashBoard;
                //setcurrentDashBoard(currentDashBoard);
                // console.log(
                //   "gotta change currentDashBoard with received data : ",
                //   currentDashBoard
                // );

                setDashBoardDepartment(currentDashBoard);
                if (dashBoardListener.current) {
                  // console.log("removing dashBoardListener.current");

                  dashBoardListener.current();
                }
                dashBoardListenerFunc(root, currentDashBoard);
              }
            });

          dashBoardDepartmentsListListener = firestore
            .collection("Companies")
            .doc(root)
            .collection("departments")
            .doc("departmentsList")
            .onSnapshot((doc) => {
              //console.log("Current data: ", doc.data());
              let depts = [];
              let data = doc.data();
              if (data) {
                data.departmentsList.map((d) => {
                  depts.push(d);
                });
                setdashBoardBoardsList(depts);
                //console.log("departmentsList " + JSON.stringify(doc.data()));
              }
            });
        })

        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);

      alert("error occurred. " + error);
    }

    return () => {
      // db.ref("AdminDashBoardDepartment").off("value", listener);
      removeListeners();
      dettachListeners();
      dettachPresenceConditionListeners();
      currentDashBoardListener();
      if (dashBoardListener.current) {
        console.log("removing dashBoardListener.current");

        dashBoardListener.current();
      }
      dashBoardDepartmentsListListener();
      console.log("Ok about to unmount and remove ref.");
    };
  }, []);

  function signOut() {
    app.auth().signOut();
  }

  // function saveDepartment() {}

  function handleDepartmentSubmit(e) {
    // e.preventDefault();

    try {
      // console.log("You selected: " + e.value);
      console.log("submit " + e);
      if (currentDocRoot === "" || dashBoardDepartment === e) {
        return console.log("Nothing to update.");
      } else {
        let ref = firestore
          .collection("Companies")
          .doc(currentDocRoot)
          .collection("currentDashBoard")
          .doc("currentDashBoard");

        ref.set({ currentDashBoard: e });
      }
    } catch (error) {
      alert("error: " + error);
    }
  }

  async function ModifyDepartment(toAdd, yourData) {
    setdepartmentModifying(true);
    if (currentDocRoot === "") {
      return console.log("Error Modifying.");
    } else if (currentDocRoot === yourData) {
      return console.log("Nothing to Modify.");
    } else {
      if (yourData === "All") {
        alert("can't modify 'All'.");
        setdepartmentModifying(false);

        return;
      }

      const deptListRef = firestore
        .collection("Companies")
        .doc(currentDocRoot)
        .collection("departments")
        .doc("departmentsList");

      if (toAdd) {
        try {
          await deptListRef.update({
            departmentsList: FieldValue.arrayUnion(yourData),
          });
          await firestore
            .collection("Companies")
            .doc(currentDocRoot)
            .collection("departments")
            .doc(yourData)
            .set({ users: [] });
        } catch (error) {
          alert(error);
        } finally {
          setdepartmentModifying(false);
        }
      } else {
        try {
          await deptListRef.update({
            departmentsList: FieldValue.arrayRemove(yourData),
          });

          if (dashBoardDepartment === yourData) {
            await firestore
              .collection("Companies")
              .doc(currentDocRoot)
              .collection("currentDashBoard")
              .doc("currentDashBoard")
              .update({ currentDashBoard: "All" });

            //setDashBoardDepartment(currentDashBoard);
          }
          //not necessary -
          //remove all users list assigned on that department as well.
          await firestore
            .collection("Companies")
            .doc(currentDocRoot)
            .collection("departments")
            .doc(yourData)
            .delete();
        } catch (error) {
          alert(error);
        } finally {
          setdepartmentModifying(false);
        }
        // await deptListRef.get().then((doc) => {
        //   let data = doc.data().departmentsList;
        //   // let temp = [...data];

        //   var index = data.indexOf(yourData);
        //   if (index === -1) {
        //     alert("Doesn't exist.");
        //     setdepartmentModifying(false);

        //     return;
        //   }
        //   // var filteredAry = data.filter((e) => e !== yourData);
        //   // data.splice(index, 1);

        //   // console.log(JSON.stringify(data));

        //   deptListRef.set({ departmentsList: data }).then(() => {
        //     setdepartmentModifying(false);
        //   });
        // });
      }
    }
  }

  async function assignUserToADepartment(addOrRemove, uid, department) {
    var departmentsRef = firestore
      .collection("Companies")
      .doc(currentDocRoot)
      .collection("departments")
      .doc(department);

    //to notify this user that he should show users presence of that department.
    var UserDeptRef = firestore
      .collection("Companies")
      .doc(currentDocRoot)
      .collection("Users")
      .doc(uid)
      .collection("department")
      .doc("department");

    // var usersRef = firestore
    //   .collection("Companies")
    //   .doc(currentDocRoot)
    //   .collection("Users")
    //   .doc(uid);
    // await departmentsRef.update({ id: "Hi" });

    // return;
    addOrRemove
      ? console.log("Hey you should assign ...", uid, " To ", department)
      : console.log("Hey you should remove ...", uid, " from ", department);
    try {
      if (addOrRemove) {
        assignedFromAll.current = true;

        await departmentsRef.update({
          users: FieldValue.arrayUnion(uid),
        });
        await UserDeptRef.update({
          department: department,
        });

        //you can ignore following bit of code...
        //need to check again because changing somewhere else in between.
        if (assignedFromAll.current) assignedFromAll.current = false;
      } else {
        await departmentsRef.update({
          users: FieldValue.arrayRemove(uid),
        });
        await UserDeptRef.update({
          department: "",
        });
        return "Success";
      }
    } catch (error) {
      console.log(error);
      return "Failure";
    }
  }

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    setLocale(checked ? "ar" : "en");
  };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  //
  const [isOpen, toggle] = useState(false);

  const handleModal = () => {
    //console.log("modal button pressed.");
    toggle(!isOpen);
  };

  const setMainView = (param) => {
    setShowMainView(param);
  };
  const setMainView1 = (param) => {
    whereToScrollTo.current = "";
    setShowMainView(param);
  };

  function showLogsView(params) {
    setShowMainView(_AdvancedDataPresentation);
  }
  function toggleShowChat() {
    setShowChat(!ShowChat);
  }

  const scrollSetForGraph = (param) => {
    whereToScrollTo.current = param;
    setMainView(_GraphsView);
  };

  // const showMainDashBoard = () => {
  //   setShowMainView("MainDashBoard");
  // };

  // const showDepartments = () => {
  //   setShowMainView("Departments");
  // };

  // const resetState = () => {};
  // const dummyDashboards = ["All", "RRHH", "ADMIN", "ETC"];
  const renderMainView = (param) => {
    switch (param) {
      case _MainDashBoard:
        return (
          <Main
            // image={image}
            // toggled={toggled}
            // collapsed={collapsed}
            // rtl={rtl}
            toggle={toggle}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            handleRtlChange={handleRtlChange}
            // handleImageChange={handleImageChange}
            handleModal={handleModal}
            isOpen={isOpen}
            showMainView={setMainView}
            dashBoardDepartment={dashBoardDepartment}
            // refreshAllUsers={getAllUsers}
            // x={myTest}
            // totalUsers={allUsers.length}
            TotalMinutes={TotalMinutes}
            TotalOutGoingCalls={TotalOutGoingCalls}
            TotalIncomingCalls={TotalIncomingCalls}
            TotalMissedCalls={TotalMissedCalls}
            // currentUsersToTrack={currentUsersToTrack}
            // dashBoardBoardsList={dashBoardBoardsList}
            dashBoardDepartment={dashBoardDepartment}
            // allUsers={allUsers}
            totalUsers={totalUsers}
            users={users}
            usersToShow={usersToShow}
            // getAllUsers={getAllUsers}
            // Stats={Stats}
            // PresenceConditions={PresenceConditions}
            // Presence={arrayState}
            // arrayPresence={arrayPresence}
            Presence={Presence}
            ShowChat={ShowChat}
            scrollSetForGraph={scrollSetForGraph}
          />
        );
      case _DepartmentsView:
        return (
          <SelectDepartment
            // toggled={toggled}
            // collapsed={collapsed}
            // rtl={rtl}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            handleRtlChange={handleRtlChange}
            dashBoardDepartment={dashBoardDepartment}
            handleSubmit={handleDepartmentSubmit}
            ModifyDepartment={ModifyDepartment}
            Departments={dashBoardBoardsList}
            departmentModifying={departmentModifying}
          />
        );
      case _GraphsView:
        return (
          <UserGraphs
            // usersToShow={usersToShowUnSorted}
            usersToShow={users}
            Stats={Stats}
            handleToggleSidebar={handleToggleSidebar}
            dashBoardDepartment={dashBoardDepartment}
            showLogsView={showLogsView}
            whereToScrollTo={whereToScrollTo.current}
          />
        );
      case _ProfileView:
        return (
          <Profile
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            //  handleRtlChange={handleRtlChange}
            // handleImageChange={handleImageChange}
            handleModal={handleModal}
            OwnSelf={OwnSelf.current}
            OwnSelfStats={OwnSelfStats.current}
          />
        );
      case _UsersView:
        return (
          <UserManagement
            currentUsersToTrack={currentUsersToTrack}
            dashBoardBoardsList={dashBoardBoardsList}
            dashBoardDepartment={dashBoardDepartment}
            // allUsers={allUsers}
            allUsers={users}
            handleToggleSidebar={handleToggleSidebar}
            assignUserToADepartment={assignUserToADepartment}
            // users={users.current}
          />
        );
      case _AdvancedDataPresentation:
        return (
          <AdvancedDataPresentation
            currentUsersToTrack={currentUsersToTrack}
            dashBoardBoardsList={dashBoardBoardsList}
            dashBoardDepartment={dashBoardDepartment}
            // allUsers={allUsers}
            allUsers={users}
            handleToggleSidebar={handleToggleSidebar}
            assignUserToADepartment={assignUserToADepartment}
            // users={users.current}
            setTestVar={setTestVar}
            testVar={testVar}
            currentDocRoot={currentDocRoot}
            currentUsersToTrack={currentUsersToTrack}
            handleToggleSidebar={handleToggleSidebar}
            // allUsers={users}
            dashBoardDepartment={dashBoardDepartment}
          />
        );
      default:
        return (
          <div>
            <p>Some error occurred.</p>
            <p>
              Please check network and provide correct command. Or contact
              developer.
            </p>
          </div>
        );
    }
  };

  return (
    <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
      <Aside
        image={image}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCreateUserClick={handleModal}
        // showMainDashBoard={showMainDashBoard}
        // showDepartments={showDepartments}
        setMainView={setMainView1}
        ShowMainView={ShowMainView}
        LogOut={signOut}
        toggleShowChat={toggleShowChat}
        setLocale={setLocale}
        locale={locale}
      />
      {renderMainView(ShowMainView)}
    </div>
  );
}

export default AdminDashBoard;
