import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

function AdvancedDataTable({
  logsOnSelectedData,
  dashBoardDepartment,
  sortUp,
  sortDown,
}) {
  function callFormatter(cell, row) {
    if (row.type === "Missed") {
      return (
        <span>
          <strong style={{ color: "red" }}> {cell} </strong>
        </span>
      );
    }

    return <span>{cell}</span>;
  }

  function timeFormatter(column, colIndex) {
    return (
      <div>
        <strong>{column.text}</strong>{" "}
        <Button variant="outline-dark" size="sm" onClick={sortUp}>
          <FaChevronUp />
        </Button>
        <Button variant="outline-dark" size="sm" onClick={sortDown}>
          <FaChevronDown />
        </Button>
      </div>
    );
  }

  const columns = [
    {
      dataField: "UName",
      text: "User Name",
      // sort: true,
    },
    {
      dataField: "type",
      text: "Call Type",
      formatter: callFormatter,
    },
    {
      dataField: "num",
      text: "Number",
    },
    {
      dataField: "dur",
      text: "Duration",
    },
    {
      dataField: "date",
      text: "Date and Time",
      // sort: true,
      headerFormatter: timeFormatter,
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-success" onClick={handleClick}>
          Export to CSV
        </button>
      </div>
    );
  };

  return (
    <div
      style={{
        padding: "4% 4%",
        // backgroundColor: "#DEDADA",
      }}
    >
      <div className="row">
        <div className="col-12 col-md-8">
          <h6 className="h6" style={{ color: "#00006B" }}>
            {"Department: " + dashBoardDepartment}
          </h6>
          <p></p>
        </div>
      </div>

      <div style={{ color: "#4682B4" }}>
        <ToolkitProvider
          keyField="id"
          data={logsOnSelectedData}
          columns={columns}
          exportCSV
        >
          {(props) => (
            <div>
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
              <hr />
              <MyExportCSV {...props.csvProps} />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default AdvancedDataTable;
