// import * as firebase from "firebase/app";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
});

const auth = firebase.auth;
const db = firebase.database();
const firestore = firebase.firestore();
const _storage = firebase.storage();
const FieldValue = firebase.firestore.FieldValue;
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
const Storage_TaskEvent = firebase.storage.TaskEvent;

const BACKEND_BASE_URL = "https://us-central1-mov365pro-f024c.cloudfunctions.net/api/"; //replace this with your firebase functions URL, localhost URL - "http://localhost:5000/mov365pro/us-central1/api/"

firestore.settings({
  timestampsInSnapshots: true,
});

export default app;

export {
  auth,
  db,
  firestore,
  _storage,
  Storage_TaskEvent,
  FieldValue,
  EmailAuthProvider,
  BACKEND_BASE_URL,
};
